import React, { useEffect, useState } from 'react';
import CountDown from '../CountDown';
import { ButtonTitleHelper } from '../../../../../helpers/buttontitle.helper';
import { ListingPageHelper } from '../../../../../helpers/ListingPage.helper';
import SetThemeColor from "../../../../../utils/setThemeColor";
import { Link } from 'react-router-dom';
// import Lazyload from 'react-lazyload'

const ItemCard = ({
  index,
  theme_color,
  video = '',
  image,
  logo,
  deadline,
  price,
  title,
  itemDetailUrl = '',
  colors = { body: '#BA0101', secondary: '#022163', textColors: '#fff' },
  addToWishlist,
  AuthStore,
  added,
  skuNumber,
  Quantity,
  masterQuantity,
  Membership,
  key,
  style,
  description,
  itemType,
  previewAsset,
  channel_name,
}) => {
  const [bidPrice, setBidPrice] = useState(price);
  const [bidClose_at, setClose_at] = useState(deadline);

  useEffect(() => {
    SetThemeColor(theme_color)
    if (itemType == 'Bid Only' && AuthStore.isAuthenticated) {      
      //here is fired on component did mount.
      const channel = AuthStore.pusher.subscribe(channel_name);
      const get_channel_name = `${channel_name}_bid_placed`;
      channel.bind(get_channel_name, (data) => {
        const bid = data.data.bid.price;
        setBidPrice(bid);

        if (data.data?.closes_at) {
          let close_time = new Date(data.data?.closes_at * 1000);
          setClose_at(close_time);
        }
      });
      return () => {
        //here is fired on component unmount.
        
      };
    }
  }, []);

  const Loading = () => (
    <div className="post loading">
      <h5 style={{ marginTop: '50%' }}> Loading...</h5>
    </div>
  );

  return (
    <div className="rounded shadow reversebg mkitems">
      {/* <Lazyload key={key} placeholder={<Loading />} offset={-400}> */}
      <div className="px-5 py-2 d-flex align-items-center text-start" style={{ height: '75px', minHeight: '75px' }}>
        {' '}
        <h4 className="text-uppercase" style={{ fontSize: '14px' }}>
          {title}
        </h4>
      </div>
      {skuNumber && masterQuantity ? (
        <>
          {Membership == false ? (
            <div
              className="px-5 py-2 text-start"
              style={{ fontSize: '13px', marginTop: '-15px' }}
            >{`#${skuNumber} of ${masterQuantity}`}</div>
          ) : (
            <div
              className="px-5 py-2 text-start"
              style={{ fontSize: '13px', marginTop: '-15px' }}
            >{`#${skuNumber}`}</div>
          )}
        </>
      ) : null}
      <div style={{ position: 'relative' }}>
        <Link className="" to={itemDetailUrl}>
          {video ? (
            <video className="DynamicVideo" autoPlay playsInline muted loop src={video} />
          ) : (
            <img src={image} alt={title} className="item__image" />

            // <img
            //   src="https://igotit-staging.s3.amazonaws.com/photo/photo/1491/7bcd41cad6.jpg"
            //   alt="Green Tea"
            //   class="item__image"
            // ></img>
          )}
        </Link>
      </div>
      {/* </Lazyload> */}
      <div className="text-start p-5">
        {AuthStore?.isAuthenticated && (
          <span tooltip={added ? 'Remove from Wishlist' : 'Add to Wishlist'} flow="right">
            <i
              style={{ fontSize: '18px', paddingBottom: '15px' }}
              className={`fa fa-heart${added ? '' : '-o'}`}
              aria-hidden="true"
              onClick={addToWishlist}
            ></i>
          </span>
        )}

        {logo ? <img src={logo} alt={title} className="logo" /> : null}

        <div>
          {itemType == 'Bid Only' ? (
            <p className="price" style={{ fontSize: '13px', margin: '0' }}>
              Current Bid
            </p>
          ) : null}
          {itemType == 'Buy Only' ? (
            <p className="price" style={{ fontSize: '13px', margin: '0' }}>
              Offer Price
            </p>
          ) : null}
          {itemType == 'Raffle' ? (
            <p className="price" style={{ fontSize: '13px', margin: '0' }}>
              Entry Fee
            </p>
          ) : null}

          {price == 'Free' ? (
            <p className="price" style={{ display: 'inline-block' }}>
              {itemType == 'Bid Only' ? bidPrice : price}
            </p>
          ) : (
            <p
              className="price pb-1 m-0"
              style={{
             
                display: 'inline-block',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              ${itemType == 'Bid Only' ? bidPrice : price}
            </p>
          )}
        </div>
        {/* <div>
        <p  style={{width: "60px", marginleft: "5" }} className='icon'>{AuctionIcon(this.props.data?.item_type,this.props.data?.auction_type)}</p>

        </div> */}
        <div>
          <p style={{ display: 'inline-block', fontSize: '13px', margin: '0' }}>Time Remaining </p>
          <CountDown
            style={{
             
              fontSize: '16px',
              fontWeight: 'bold',
            }}
            deadline={itemType == 'Bid Only' ? bidClose_at : deadline}
          />
        </div>

        <Link
          className="text-start p-0 pt-3 DynamicButton"
          // style={{ backgroundColor: colors.secondary, color: colors.textColors, marginBottom: '10px' }}

          to={itemDetailUrl}
        >
          <button
            className="py-3 px-5 rounded mb-0 mt-3 viewbtn"
            style={{
             
              marginBottom: '10px',
              border: '0',
              fontWeight: '600',
            }}
          >
            {ListingPageHelper(itemType)}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ItemCard;
