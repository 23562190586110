import React, { Component } from 'react'
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { notification, Popconfirm, Spin, DatePicker, Select } from "antd";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import moment from "moment";
import Platforms from './../Platforms';
import { transform } from '@babel/core';
let { Option } = Select;



@inject("MarketPlace")
@observer
class TransferModal extends Component {
  state = {
    wallet_id: "",
    platform:"",
    loading: false,
    
  };

  
  


 
  transferitem = async () => {
  
    const {wallet_id, platform, loading} = this.state   
    console.log({wallet_id,platform})

    const transferdata={
      wallet_id:this.state.wallet_id,
      platform:this.state.platform,
      event_item_id: this.props.data.event_item.short_event_item.id,
      sku_id: this.props.data.event_item.short_event_item.item.sku_id
    
      
    }

    
    
    await this.props.MarketPlace.TransferData(transferdata);
    if(this.props.MarketPlace.error){
      notification.error({
        message:`Error`,
        description: `${this.props.MarketPlace.error.errors ? this.props.MarketPlace.error.errors : this.props.MarketPlace.error.errors[0]}`,
        placement:"radius-bottomLeft"
      });
      this.setState({loading:false})
      this.props.setModal_Popup(false)
      return;
    }
    notification.success(
      {
        message:`Success`,
        description:"Item Transfer successfully",
        placement:"radius-bottomRight"
      })
      this.setState({loading:false})
      this.props.setModal_Popup(false)
      return;
  }
  
  render() {
      
  
  
    return (
      <Spin spinning={this.state.loading}>
        <ModalBody>
          <div className="modal-body">
            <div className="row justify-content-center ">
              <div className="login-form">
                <h2 style={{ fontSize: '18px', textAlign: 'center', marginBottom: '20px' }}>
                  Transfer Item
                </h2>
                <label style={{ fontSize: '15px', marginBottom: '10px' }} >Platform</label>
                <div className="form-group">
                <div className="input-group">
                <Select
                  showSearch
                  
                  style={{ width: "100%" }}
                  name="paltform"
                  value={this.state.platform}
                  placeholder="Platform" required
                  onChange={(e) => {
                    this.setState({ platform: e });
                  }}
                >
                  <Option disabled="true" value=""> {"Select Platform"}</Option>
                  <Option value="1"> {"MetaMask"}</Option>
                  <Option value="2"> {"Coinbase Wallet"}</Option>
                
                </Select>
                    
                    </div>
                    </div>
                <label style={{ fontSize: '15px', marginBottom: '10px' }} >Wallet ID</label>
                <div className="form-group"> 
                <div className="input-group">
                    <input type="text"
                      style={{ borderLeft: '0px' }}
                      className="form-control"
                      name="walletid"
                      value={this.state.wallet_id}
                    
                      placeholder="wallet-id" required 
                      onChange={(e)=>{this.setState({wallet_id:e.target.value})  }}
                    
                      
                      />
                  
                  </div>
                </div>
             
                  
                


                {/* <DatePicker size={"large"}
                  style={{ width: "100%", marginLeft: '20px', paddingTop: '10px', paddingBottom: '10px' }}
                  placeholder="Select Close Date"
                  disabledDate={d => !d || d.isBefore(date)}
                  format='MM-DD-YYYY'
                  onChange={(momen, date) => {
                    let d = Math.round(new Date(date).getTime() / 1000).toString();
                    this.setState({ dob: d });
                  }}
                /> */}
             
                
                  
              
            
                  <Button className="DynamicButton"   onClick={() => this.transferitem()} style={{ width: "100%", backgroundColor: '#101f3e', color: 'white', borderRadius: '5px', height: '40px', border: 'none', marginTop: "5%" }} type="submit">Submit</Button>
              

              
              </div>
            </div>
          </div>
        </ModalBody >
      </Spin >
    )
  }
}


export default TransferModal;
