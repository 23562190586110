import { observable, action, computed } from "mobx";
import axios from "../axios/index";

class ReportsStore {
  @observable reports = [];
  @observable hospitals = [];
  @observable count = 1;
  @observable action = false;
  @observable errors = "";
  @observable headers = []


  @action setErrors = () => {
    this.errors = "";
  };

  @action setAction = action => {
    this.action = action;
  };

  @action getAllReports = async data => {
    try {
    const reportdata = await axios.get(`/source/sources?offset=${data.offset}`);
    this.reports = reportdata.data.reports;
    this.count = reportdata.data.count;
  }
  catch(e) {

  }

}
@action checkSource = async (data, history) => {
    this.setErrors()
  try {
    const adddata = await axios.post(`/source/checksource`, data);
    if(adddata.data.ok) {
      this.action = true;
      this.headers = adddata.data.headers
      return true
      
    }
    this.errors = adddata.data.message
  } catch (e) {
    console.log(e)
    this.errors = e.response?.data.message ?? e.data.message;
  }
    return false
}

  @action addReport = async (data, history) => {
    this.setErrors()
    try {
      const Useradd = await axios.post(`/source/sendsource`, data);
      if (Useradd.data.ok) {
        this.action = true;
        const adderror = Useradd.data;
        history.push("/sources");
      }
      this.error = Useradd.data.message
    } catch (e) {
      this.errors = e.response.data.message;
    }
  }
@action getHospitals = async (data) => {
  const hospitaldata = await axios.get(`/hospital`);
  console.log(hospitaldata);
  this.hospitals = hospitaldata.data.hospitals;
}
}

export default ReportsStore;
