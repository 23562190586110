import React from 'react';
import { observer, inject } from 'mobx-react';
import { loadStripe } from '@stripe/stripe-js';
import { notification } from 'antd';
import {
  CardElement,
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { ModalBody, ModalFooter, Button } from 'reactstrap';
import Addcardicon from '../../../../helpers/cardicon';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

@inject('StripeStore')
class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      brand: 'fa fab fa-credit-card',
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleBrandChange = (e) => {
    let brand = Addcardicon(e.brand);
    this.setState({ brand });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { elements, stripe, StripeStore } = this.props;
    const cardNumberElement = elements.getElement(CardNumberElement);
  
    try {
      const response = await StripeStore.getSetupStripeIntent();
      console.log(response); // Log the response to check its structure and contents
  
      const { error, setupIntent } = await stripe.confirmCardSetup(response.client_secret, {
        payment_method: { card: cardNumberElement },
      });
  
      if (error) {
        notification.error({
          message: `Error`,
          description: `${error.message}`,
          placement: 'radius-bottomleft',
        });
      } else {
        this.props.handle(setupIntent.payment_method);
        cardNumberElement.clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
      }
    } catch (error) {
      console.error('Failed to confirm card setup:', error);
    }
  };
  

  render() {
    const { stripe } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit} className="text-start">
          <div id="PaymentForm" style={{ textAlign: 'left' }}>
            <div className="App-payment">
              <label className="text-left" htmlFor="state">
                Card Number
              </label>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className={this.state.brand} style={{ fontSize: '25px' }}></i>
                    </span>
                  </div>
                  <CardNumberElement
                    onChange={this.handleBrandChange}
                    className="StripeElement form-control p-4"
                    options={{
                      style: {
                        base: {
                          fontSize: '20px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="" htmlFor="state">
                    Expiry Date
                  </label>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend"></div>
                      <CardExpiryElement
                        onChange={this.handleInputChange}
                        value={this.state.cardExpiry}
                        className="StripeElement form-control p-4"
                        options={{
                          style: {
                            base: {
                              fontSize: '20px',
                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="CVC">CVC/CVV</label>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend"></div>
                      <CardCvcElement
                        onChange={this.handleInputChange}
                        value={this.state.cardCvc}
                        className="StripeElement form-control p-4"
                        options={{
                          style: {
                            base: {
                              fontSize: '20px',
                              margin: '5px',

                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button type="submit" className="btn btn-primary px-3 py-3 account" disabled={!stripe} color="primary">
            Add
          </Button>
        </form>
      </React.Fragment>
    );
  }
}
const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => <CheckoutForm stripe={stripe} elements={elements} handle={props.handle} />}
  </ElementsConsumer>
);
export const PaymentCard = (props) => (
  <Elements stripe={stripePromise}>
    <InjectedCheckoutForm handle={props.handleSubmit} />
  </Elements>
);
