import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { notification } from 'antd';
import {
  CardElement, Elements, ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import { ModalBody, ModalFooter, Button } from 'reactstrap';
import Addcardicon from '../../../../helpers/cardicon';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

class CheckoutForm extends React.Component {

  state = {
     brand: 'fa fab fa-credit-card',
    
  };

  handleInputChange = (e) => {
    // console.log(e.brand + "brand")

    let brand=Addcardicon(e.brand)
    // console.log(brand + "brand2")
    this.setState({brand})

  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { elements, stripe } = this.props;
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, token } = await stripe.createToken(cardNumberElement);

    if (error) {
      notification.error({
        message: `Error`,
        description: `${error.message}`,
        placement: 'radius-bottomleft',
      });
    } else {
      this.props.handle(token);
    }
  };

  
  render() {
    const { stripe } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <ModalBody>

            {/* <CardElement options={{ hidePostalCode: true }} /> */}
            <div id="PaymentForm">
              <div className="App-payment">
                <label className="" htmlFor="state">
                  Card Number
                </label>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className={this.state.brand} style={{ fontSize: "25px" }}></i>
                      </span>
                    </div>
                    <CardNumberElement 
                    onChange={this.handleInputChange}
                      className="StripeElement form-control p-4"
                      options={{
                        style: {
                          base: {
                            fontSize: '20px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },

                        },
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label className="" htmlFor="state">
                      Expiry Date
                    </label>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                        </div>
                        <CardExpiryElement
                          className="StripeElement form-control p-4"
                          options={{
                            style: {
                              base: {
                                fontSize: '20px',
                                color: '#424770',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },

                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <label htmlFor="CVC">CVC/CVV</label>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                        </div>
                        <CardCvcElement
                          className="StripeElement form-control p-4"
                          options={{
                            style: {
                              base: {
                                fontSize: '20px',
                                margin: '5px',

                                color: '#424770',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="DynamicButton" type="submit" disabled={!stripe} color="primary">Add</Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => <CheckoutForm stripe={stripe} elements={elements} handle={props.handle} />}
  </ElementsConsumer>
);

export const AddCard = (props) => (
  <Elements stripe={stripePromise}>
    <InjectedCheckoutForm handle={props.handleSubmit} />
  </Elements>
);
