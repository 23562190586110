import { observable, action } from "mobx";
import { get, post } from "../services/ajax.service";
import { createContext } from 'react'

class EventStore {
    @observable events = [];
    @observable featuredevents = [];
    @observable eventitems = [];
    @observable eventchallengeuser = [];
    @observable eventchallengeuserItems = [];
    @observable buyitems = {};
    @observable authenticityData = {};
    @observable eventitemfilters = {};
    @observable mysteryboxitem = {};
    @observable mysteryboxcredit = {};
    @observable mysteryboxship = {};
    @observable eventName = {};
    @observable eventitemsdetails = [];
    @observable redeempoints = [];
    @observable giveawayitems = [];
    @observable giveawaydrawnitems = [];
    @observable redeempointdetails = [];
    @observable pagination = {};
    @observable carouseleventitems = [];
    @observable filterDuplicationRemove = [];
    @observable Tempeventitems = [];
    @observable errortoggle = false;
    @observable errors = "";
    @observable error = {};
    @observable upcomingevents = [];
    @observable latest_purchased_items = [];


    @observable mainwebappdata = {};

    @observable certification = {};





    @action getEventName = async (data, options = {}) => {
        this.eventName = {};
        try {
            let response = await get(
                // `/events.json?id=${data.id}&show_closed=${false}`, options);
                `/events.json?id=${data.id}`, options);

            this.eventName = response.data.event;

        } catch (err) {
            console.log(err);
        }
    }
    @action getEventChallengeUsers = async (data, options = {}) => {
        this.eventchallengeuser = [];
        try {
            let response = await get(
                // `/events.json?id=${data.id}&show_closed=${false}`, options);
                `/users/user_challenge_items.json`, options);

            this.eventchallengeuser = response.data.users;

        } catch (err) {
            console.log(err);
        }
    }

    @action getMysteryBoxItem = async (data, options = {}) => {
        this.mysteryboxitem = {};
        try {
            let response = await get(
                `/event_items/instant_reveal_now.json?request_id=${data.request_id}`, options);

            this.mysteryboxitem = response.data.item;
            this.errortoggle = false;
        } catch (err) {
            this.error=err.response.data;
            this.errortoggle = true;
        }
    }

    @action getMysteryBoxCredit = async (data, options = {}) => {
        this.mysteryboxcredit = {};
        try {
            let response = await get(
                `/event_items/mystery_credit_balance.json?request_id=${data.request_id}`, options);

            this.mysteryboxcredit = response.data.message;
            this.errortoggle = false;
        } catch (err) {
            this.error=err.response.data;
            this.errortoggle = true;
        }
    }

    @action instantShipSurprise = async (data, options = {}) => {
        this.mysteryboxship = {};
        try {
            let response = await post(
                `event_items/instant_ship_surprise.json?request_id=${data.request_id}`, options);

            this.mysteryboxship = response.data.message;
            this.errortoggle = false;
        } catch (err) {
            this.error=err.response.data;
            this.errortoggle = true;
        }
    }


    @action getEventChallengeUsersItems = async (options = {}) => {
        this.eventchallengeuserItems = [];
        try {

            const response = await get("/users/my_challenge_items.json",options);
            this.eventchallengeuserItems = response.data.users;
        }
        catch (err) {
            this.errors=err.responce;
        }
    }; 
       @action sort_event_items = (eventItems) => {
        let EventItemsTemp = [];
        eventItems.map(ei => {
            if (ei.status == 'sold') {
                EventItemsTemp.push(ei)
            }
        })
        this.eventitems = eventItems.slice().sort(function (a, b) {
            if (a.closes_at < b.closes_at) return -1;
            if (a.closes_at > b.closes_at) return 1;
            return 0
        })
        this.eventitems = this.eventitems.filter(obj => obj.status !== 'sold')
        this.eventitems = [...this.eventitems, ...EventItemsTemp]
    }

    @action sortby(filter, type = "A") {
        if (this.eventitems.length > 0) {
            if (filter == 1 && type == "A") {
                console.log('working')
                this.eventitems = this.eventitems.slice().sort(function (a, b) {
                    if (parseInt(a.item_type == "Bid Only" ? a.current_bid?.price ? a.current_bid?.price : a.price : a.price) < parseInt(b.item_type == "Bid Only" ? b.current_bid?.price ? b.current_bid?.price : b.price : b.price)) return -1;
                    if (parseInt(a.item_type == "Bid Only" ? a.current_bid?.price ? a.current_bid?.price : a.price : a.price) > parseInt(b.item_type == "Bid Only" ? b.current_bid?.price ? b.current_bid?.price : b.price : b.price)) return 1;
                    return 0
                })
            }
            if (filter == 2 && type == "A") {
                this.eventitems = this.eventitems.slice().sort(function (a, b) {
                    if (parseInt(a.closes_at) < parseInt(b.closes_at)) return -1;
                    if (parseInt(a.closes_at) > parseInt(b.closes_at)) return 1;
                    return 0
                })
            }
            if (filter == 1 && type == "D") {
                this.eventitems = this.eventitems.slice().sort(function (a, b) {
                    if (parseInt(a.item_type == "Bid Only" ? a.current_bid?.price ? a.current_bid?.price : a.price : a.price) > parseInt(b.item_type == "Bid Only" ? b.current_bid?.price ? b.current_bid?.price : b.price : b.price)) return -1;
                    if (parseInt(a.item_type == "Bid Only" ? a.current_bid?.price ? a.current_bid?.price : a.price : a.price) < parseInt(b.item_type == "Bid Only" ? b.current_bid?.price ? b.current_bid?.price : b.price : b.price)) return 1;
                    return 0
                })
            }
            if (filter == 2 && type == "D") {
                this.eventitems = this.eventitems.slice().sort(function (a, b) {
                    if (parseInt(a.closes_at) > parseInt(b.closes_at)) return -1;
                    if (parseInt(a.closes_at) < parseInt(b.closes_at)) return 1;
                    return 0
                })
            }
        }
    }

    @action getLiveEvents = async (options = {}) => {
        this.events = [];
        try {
            let response = await get(
                `/events.json?show_closed=${false}`, options);

            this.events = response.data.events;
        } catch (err) {
            console.log(err);
        }
    }
    @action getFeaturedEvents = async (options = {}) => {
        this.featuredevents = [];
        try {
            let response = await get(`/events/featured_events.json`);

            this.featuredevents = response.data.events;
        } catch (err) {
            console.log(err);
        }
    }

    @action getLatestPurchaseditems = async () => {
        // this.latest_purchased_items = [];
        try {
            let response = await get(`/event_items/latest_purchased_items.json`);
            this.latest_purchased_items = response.data.latest_purchased_items;
        } catch (err) {
            console.log(err);
        }
    }






    @action getUpcomingEvents = async () => {
        this.upcomingevents = [];
        try {
            let response = await get(`/events/upcoming_events.json`);
            this.upcomingevents = response.data.events;
        }
        catch (err) {
            console.log(err);
        }
    }

    getUniqueListBy = (arr) => {
        return [...new Map(arr.map(item => [item.item.title, item.item.title])).values()]
    }

    @action getFilterMarketPlaceData = async (arrayData) => {
        let tempArray = [];
        try {
            if (arrayData.length == 0) {
                this.Tempeventitems = this.eventitems;
                return;
            }
            this.eventitems.map((t) => {
                arrayData.map((f) => {
                    if (t.item.title == f) {
                        tempArray.push(t);
                    }
                })
            })
            this.Tempeventitems = tempArray;
        } catch (err) {
            console.log(err);
        }
    }


    @action getMarketPlaceEventItems = async (data, options = {}) => {
        this.eventitems = [];
        try {
            let response = await get(
                `/market_place/items.json`
            );

            this.eventitems = response.data.event_items;
            this.Tempeventitems = response.data.event_items;
            this.eventitems = this.eventitems.sort(function (a, b) {
                if (a.title < b.title) { return -1; }
                if (a.title > b.title) { return 1; }
                return 0;
            })
            this.filterDuplicationRemove = this.getUniqueListBy(this.eventitems);

        } catch (err) {
            console.log(err);
        }
    }

    @action getEventItems = async (data, options = {}) => {
        this.eventitems = [];
        this.eventitemsdetails = [];
        this.pagination = {};
        try {
            let apiUrl = '/event_items.json';
            apiUrl += `?${new URLSearchParams(data)}`;
            // let response = await get(
            //     `/event_items.json?event_id=${data.id}`, options
            // );
            let response = await get(apiUrl, options);

            this.eventitems = response.data.event_items;
            this.pagination = response.data.pagination;
            // this.sort_event_items(this.eventitems)
        } catch (err) {
            console.log(err);
        }
    }

    @action getEventItemFilters = async (data, options = {}) => {
        this.eventitemfilters = [];
        try {
            let response = await get(
                `/event_items/event_item_filters.json?event_id=${data.id}`, options
            );

            this.eventitemfilters = response.data.filters;
        } catch (err) {
            console.log(err);
        }
    }

    @action getRedeemPoints = async (data, options = {}) => {
        this.redeempoints = [];
        this.Tempeventitems = [];
        try {
            let response = await get(
                `/event_items/point_items.json`, options
            );

            this.redeempoints = response.data.event_items;
            this.Tempeventitems = response.data.event_items;
        } catch (err) {
            console.log(err);
        }
    }

    @action getGiveAwayItems = async (data, options = {}) => {
        this.giveawayitems = [];
        this.pagination = {};
        try {
            let response = await get(
                `/raffles/open_giveaway_items.json?page=${data.page}&api_key=${data.api_key}`, options
            );

            this.giveawayitems = response.data.event_items;
            this.pagination = response.data.pagination
        } catch (err) {
            console.log(err);
        }
    }

    @action getGiveAwayDrawnItems = async (data, options = {}) => {
        this.giveawaydrawnitems = [];
        this.pagination = {};
        try {
            let response = await get(
                `/raffles/sold_giveaway_items.json?page=${data.page}&api_key=${data.api_key}`, options
            );

            this.giveawaydrawnitems = response.data.event_items;
            this.pagination = response.data.pagination
        } catch (err) {
            console.log(err);
        }
    }

    @action getMainWebAppData = async () => {
        this.mainwebappdata = {};
        try {
            let response = await get(
                `/companies/get_main_web_app.json`
            );
            this.mainwebappdata = response.data.main_web_app;
        } catch (err) {
            console.log(err);
        }
    }


    @action getSpecificEventItems = async (data, options = {}) => {
        this.eventitems = [];
        this.eventitemsdetails = [];
        try {
            let response = await get(
                `/event_items.json?event_id=${data.id}&item_type_id=${data?.item_type_id}`, options
            );

            this.eventitems = response.data.event_items;
            // this.sort_event_items(this.eventitems)
        } catch (err) {
            console.log(err);
        }
    }

    @action setPortfolioFromLocalStorage = async (data) => {
        this.eventitemsdetails = [];
        try {
            let parseData = JSON.parse(data)
            this.eventitemsdetails.push(parseData?.item ? parseData : parseData?.event_item?.short_event_item);
            return {
                sku_id: parseData?.event_item?.short_event_item?.item?.sku_id,
                item_sku_id: parseData?.item_sku_id
            };
        } catch (err) {
            console.log(err);
        }
    }

    @action getSingleEventItems = async (data, options = {}) => {
        this.eventitemsdetails = [];

        try {
            let response = {};
            if (data.marketplace) {
                response = await get(`/market_place/event_item_id.json?event_item_id=${data?.event_item_id}`, options)
            }
            else {
                response = await get(
                    `/event_items/event_item_id.json?item_type_id=${data?.item_type_id}&event_item_id=${data?.event_item_id}`, options
                );
            }

            this.eventitemsdetails.push(response.data.event_item);
            return response?.data?.event_item?.item?.sku_id ?? 0
        } catch (err) {
            console.log(err);
        }
    }


    @action buyEventItems = async (data, pathId, options = {}, quantity = 1) => {
        this.eventitems = [];
        try {
            let response;
            for (let i = 0; i < quantity; i++) {
                response = await post(
                    `/event_items/${pathId}/buy.json`, data, options
                );
            }
            this.errors = "";
            this.buyitems = response.data;
        } catch (err) {
            this.errors = err.response.data;

        }
    }

    @action eventItemsAuthenticity = async (data, options = {}) => {
       
        try {
             this.error = "";
            let response= await post(
                    `/event_items/certificate_verification.json`, data, options
                );  
                this.authenticityData = response.data;            
                return response.data;
           
        } catch (err) {
            if (err.response.data.error) {
                this.error = err.response.data.error;
                return err;
              }
              if (err.response.data.errors[0]) {
                this.error = err.response.data.errors[0];
                return err;
              }
        }
    }


    // @action buyEventItems = async (data, pathId, options = {}, quantity = 1) => {
    //     try {
    //         let response;
    //         response = await post(
    //             `/event_items/amazon_redeem.json`, data, options
    //         );
    //         this.errors = "";
    //         this.buyitems = response.data;
    //     } catch (err) {
    //         this.errors = err.response.data;

    //     }
    // }

    @action AmazonRedeemForm = async (data) => {
        this.error = null;
        this.message = "";
        try {
            let response = await post(
                `/event_items/amazon_redeem.json`, data
            )
            this.message = response.data;
            return response.data;
        } catch (err) {
            this.error = err.response.data
            console.log(err.response.data.errors);
        }
    }
    @action ChallengeRedeemForm = async (data) => {
        this.error = null;
        this.message = "";
        try {
            let response = await post(
                `/event_items/challenge_redeem.json`, data
            )
            this.message = response.data;
            return response.data;
        } catch (err) {
            this.error = err.response.data
            console.log(err.response.data.errors);
        }
    }

    @action getCarouselEventItems = async (options = {}) => {
        this.carouseleventitems = [];
        try {
            let response = await get(
                `/event_items/featured_items.json`, options
            );
            let data = []
            for (let i of response.data.event_items) {

                if (i.closes_at > new Date().getTime() / 1000) {
                    data.push(i)
                }
            }
            this.carouseleventitems = data;
        } catch (err) {
            console.log(err);
        }
    }
    @action getFeaturedItems = async (options = {}) => {
        this.carouseleventitems = [];
        try {
            let response = await get(
                `/event_items/featured_items.json?event_id=${options.id}`
            );
            let data = []
            for (let i of response.data.event_items) {

                if (i.closes_at > new Date().getTime() / 1000) {
                    data.push(i)
                }
            }
            this.carouseleventitems = data;
        } catch (err) {
            console.log(err);
        }
    }

    @action sortItems = (attribute, asc) => {
        if (asc) {
            this.Tempeventitems.replace(this.Tempeventitems.slice().sort((a, b) => (a[attribute] - b[attribute])))
            return;
        }

        this.Tempeventitems.replace(this.Tempeventitems.slice().sort((a, b) => (b[attribute] - a[attribute])))
    }
}

export default EventStore;
export const EventStoreContext = createContext(new EventStore())