
import axiosInstance from "../axios";


export default function (url=null, api_key=null, company_name=null) {
    if(!url || !api_key) {
        url = process.env.REACT_APP_BASE_URL;
        api_key = process.env.REACT_APP_API_KEY;
        sessionStorage.removeItem("company_name")
        sessionStorage.removeItem("company_details")
    }
    else {
        sessionStorage.setItem("company_name", company_name)
    }
    let headers = axiosInstance.defaults.headers;
    axiosInstance.defaults.headers = {headers, ...{"Api-Key":api_key, "Os": "Web"}};
    axiosInstance.defaults.baseURL = url;
}