import { observable, action } from "mobx";
import { post } from "../services/ajax.service";

class BidStore {

    @observable Bid = [];
    @observable maxBid = [];
    @observable errors = "";
    @observable bidResponse = "";

    @action getBid = async (data,options={}) => {
        this.Bid = [];
        this.errors = "";
        try {
            let response = await post(
                `/bids/new.json`, data,options
            );
            this.errors="";
             this.Bid = response.data;
             
        } catch (err) {
            this.errors = err.response.data.errors[0]
            // console.log(JSON.stringify(err.response.data.errors[0]));
        }
    }

    @action getmaxBid = async (data,options={}) => {
        this.maxBid = [];
        this.errors = "";
        try {
            let response = await post(
                `/bids/max_bid.json`, data,options
            );
            this.errors="";
             this.maxBid = await response.data;
             
        } catch (err) {
            this.errors = err.response.data.errors[0]
            // console.log(JSON.stringify(err.response.data.errors[0]));
        }
    }

    @action allowBuyNow = async (data,options={}) => {
        this.bidResponse = "";
        this.errors = "";
        try {
            let response = await post(
                `/bids/allow_buy_now.json`, data,options
            );
            this.errors="";
            this.bidResponse = response.data
             
        } catch (err) {
            this.errors = err.response.data.errors[0]
            // console.log(JSON.stringify(err.response.data.errors[0]));
        }
    }

}

export default BidStore;
