import { observable, action } from "mobx";
import { post,get } from "../services/ajax.service";

class RequestStore {

    @observable buyItem = [];
    @observable activity_history = [];
    @observable errors = "";

    removeDuplicates(data) {
        return  Object.values(data.reduce((r, e) => {
            let k = `${e.event_item.short_event_item.id}`;
            if(!r[k]) r[k] = {...e, count: 1}
            else r[k].count += 1;
            return r;
          }, {}))
      };



      @action getActivityHistory = async (data) => {
        this.activity_history = [];
        this.errors = "";
        try {
            let response = await get(
                `/users/user_activity_history.json`, data
            );
            this.errors="";
            this.activity_history=response.data.activity_history
        } catch (err) {
            this.errors = err.response.data
        }
    }

    @action getBid = async (data) => {
        this.buyItem = [];
        this.errors = "";
        try {
            let response = await get(
                `/requests.json`, data
            );
            this.errors="";
            // this.buyItem=this.removeDuplicates(response.data.requests);
            this.buyItem=response.data.requests
        } catch (err) {
            this.errors = err.response.data
            // console.log(JSON.stringify(err.response.data.errors[0]));
        }
    }

}

export default RequestStore;
