import React, { useEffect, useState } from 'react';
import CountDown from '../CountDown';
import { ButtonTitleHelper } from '../../../../../helpers/buttontitle.helper';
import { Link } from 'react-router-dom';
import { AuctionIcon } from '../../../../../helpers/AuctionsIconsHelper';
import {  Popconfirm } from 'antd';
import { changeantdColor } from "../../../../../utils/antdUtils";
import SetThemeColor from "../../../../../utils/setThemeColor";
import { observer, inject } from 'mobx-react';


// import Lazyload from 'react-lazyload'


const ZoomCarouselItemCard = ({
  index,
  video = '',
  image,
  logo,
  deadline,
  price,
  title,
  itemDetailUrl = '',
  colors = { body: '#BA0101', secondary: '#022163', textColors: '#fff' },
  addToWishlist,
  AuthStore,
  added,
  skuNumber,
  Quantity,
  masterQuantity,
  Membership,
  key,
  style,
  description,
  itemType,
  previewAsset,
  channel_name,
  company_url_name
}) => {
  const [bidPrice, setBidPrice] = useState(price);
  const [bidClose_at, setClose_at] = useState(deadline);

  useEffect(() => {
    const companyDetails = JSON.parse(sessionStorage.getItem('company_details'));
    sessionStorage.setItem("old_theme_color", companyDetails?.company?.theme_color)
    if(sessionStorage.getItem("company_name") == company_url_name) {
      SetThemeColor(companyDetails?.company?.theme_color)
    }
    if (itemType == 'Bid Only' && AuthStore.isAuthenticated) {
      
      //here is fired on component did mount.
      const channel = AuthStore.pusher.subscribe(channel_name);
      const get_channel_name = `${channel_name}_bid_placed`;
      channel.bind(get_channel_name, (data) => {
        const bid = data.data.bid.price;
        setBidPrice(bid);

        if (data.data?.closes_at) {
          let close_time = new Date(data.data?.closes_at * 1000);
          setClose_at(close_time);
        }
      });
      return () => {
        //here is fired on component unmount.
        
      };
    }
  }, []);

  const Loading = () => (
    <div className="post loading">
      <h5 style={{ marginTop: '50%' }}> Loading...</h5>
    </div>
  );

  return (
    <div className="marketplace__item shadow-lg reversebg" style={style}>
      <div className="reversebg m-auto" style={{ position: 'relative' }}>
        {video ? (
          <video className="DynamicVideo" autoPlay playsInline muted loop src={video} />
        ) : (
          <img src={image} alt={title} className="item__image" style={{objectFit:'unset'}} />
        )}
      </div>
      {/* </Lazyload> */}
      <div className="text-start d-flex align-items-center" style={{ marginLeft: '4em' }}>
        <div className="text-start">
          {AuthStore?.isAuthenticated && (
            <span tooltip={added ? 'Remove from Wishlist' : 'Add to Wishlist'} flow="right">
              <i
                style={{ fontSize: '18px', paddingTop: '10px' }}
                className={`fa fa-heart${added ? '' : '-o'}`}
                aria-hidden="true"
                onClick={addToWishlist}
              ></i>
            </span>
          )}
          {logo ? <img src={logo} alt={title} className="logo" /> : null}

          <div className="pt-2">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <h4 className="text-uppercase" style={{ fontSize: '14px' }}>
                {title}
              </h4>
              {skuNumber && masterQuantity ? (
                <>
                  {Membership == false ? (
                    <div
                      className="py-2 text-start"
                      style={{ fontSize: '13px', marginTop: '-15px' }}
                    >{`#${skuNumber} of ${masterQuantity}`}</div>
                  ) : (
                    <div
                      className="py-2  text-start"
                      style={{ fontSize: '13px', marginTop: '-15px' }}
                    >{`#${skuNumber}`}</div>
                  )}
                </>
              ) : null}

              <Popconfirm
                onClick={changeantdColor}
                title={
                  <div>
                    <h4 className="">{AuctionIcon(itemType, AuthStore?.auction_type)?.title}</h4>

                    <p className="mt-2 " style={{ color: 'grey', fontWeight: 'normal', width: '316px' }}>
                      {AuctionIcon(itemType, AuthStore?.auction_type)?.message}
                    </p>
                  </div>
                }
              >
                <div style={{ width: '60px', marginleft: '5' }} className="icon">
                  {AuctionIcon(itemType, AuthStore?.auction_type)?.icon}
                </div>
              </Popconfirm>
            </div>

            {itemType == 'Bid Only' ? (
              <p style={{ color: '#fff', fontSize: '13px', margin: '0' }}>Current Bid</p>
            ) : null}
            {itemType == 'Buy Only' ? (
              <p style={{ color: '#fff', fontSize: '13px', margin: '0' }}>Offer Price</p>
            ) : null}
            {itemType == 'Raffle' ? <p style={{ color: '#fff', fontSize: '13px', margin: '0' }}>Entry Fee</p> : null}

            {price == 'Free' ? (
              <p className="price  pt-4" style={{ display: 'inline-block' }}>
                {itemType == 'Bid Only' ? bidPrice : price}
              </p>
            ) : (
              <p
                className="price  m-0 pt-2"
                style={{
                  color: '#fff',
                  display: 'inline-block',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                ${itemType == 'Bid Only' ? bidPrice : price}
              </p>
            )}
          </div>

          <div>
            <p style={{ display: 'inline-block', color: '#fff', fontSize: '13px', margin: '0' }}>Time Remaining </p>
            <CountDown
              style={{
                color: '#fff',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
              deadline={itemType == 'Bid Only' ? bidClose_at : deadline}
            />
          </div>

          <Link className="text-start p-0 pt-3 DynamicButton" to={itemDetailUrl}>
            <button
              className="py-3 px-5 rounded mb-0 mt-3 viewbtn"
              style={{
                marginBottom: '10px',
                border: '0',
                fontWeight: '600',
              }}
            >
              {ButtonTitleHelper(itemType)}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ZoomCarouselItemCard;


