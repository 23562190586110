import { observable, action } from "mobx";
import { post } from "../services/ajax.service";


class RaffleStore {
  @observable errors = "";
    @action raffles = async (data) => {
        try {

            await post(`/raffles/new.json`,data);
        }
        catch (err) {
            console.log(err, "error");
            this.errors=err.response.data?.errors[0]
        }
    };
    @action discount = async (data) => {
        try {
            await post(`/user_discounts/user_discount.json`,data);
        }
        catch (err) {
            console.log(err, "error");
            this.errors=err.response.data?.errors[0]
        }
    };

}

export default RaffleStore;

