import { observable, action } from "mobx";
import { get ,post,deleted} from "../services/ajax.service";
import { createContext } from 'react';

class WishlistStore {
    @observable wishlist = [];


    @action getWishlist = async () => {
        this.wishlist = [];
        try {
            let response = await get(
                `event_items/wishlists.json`
            );

            this.wishlist = response.data.event_items;
        } catch (err) {
            console.log(err);
        }
    }

    @action addWishlist = async (data) => {
        try {
            let response = await post(
                `event_items/add_to_wishlist.json`,
                data
            );
           
        } catch (err) {
            console.log(err);
        }
    }

    @action deleteWishlist = async (data) => {       
        try {
            let response = await deleted(
                `event_items/remove_from_wishlist.json?event_item_id=${data.id}`
            );
        } catch (err) {
            console.log(err);
        }
    }



}

export default WishlistStore;
export const WishlistStoreContext = createContext(new WishlistStore())
