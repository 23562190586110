import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import PrivateRoute from './helpers/PrivateRoute';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { AddCard } from './views2/dashboard/Design/Components/AddCard';
import { observer, inject } from 'mobx-react';
import Zendesk from 'react-zendesk';
import { PaymentCard } from './views2/dashboard/Design/Components/PaymentCard';

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
const SignInModal = React.lazy(() => import('./views2/dashboard/Design/Components/signInModal'));
const EditModal = React.lazy(() => import('./views2/dashboard/Design/Components/EditModal'));
const ShippingAddress = React.lazy(() => import('./views2/dashboard/Design/Components/ShippingAddressModal'));
const AddCardInfo = React.lazy(() => import('./views2/dashboard/Design/Components/AddCardInfo'));
const DefaultLayout = React.lazy(() => import('./views2/dashboard/DefaultLayout'));

const loading = () => (
  <div className="lds-ellipsis lds-ellipsis--center">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
const Design = React.lazy(() => import('./views2/dashboard/Design/dashboard'));
const FeaturedEvents = React.lazy(() => import('./views2/dashboard/Design/featuredEvents'));
// const MarketPlace = React.lazy(() => import("./views2/dashboard/Design/itemsDetail"))
const MarketPlace = React.lazy(() => import('./views2/dashboard/Design/Marketplace'));
const GiveAwayItems = React.lazy(() => import('./views2/dashboard/Design/GiveAwayItems'));
const EventItems = React.lazy(() => import('./views2/dashboard/Design/eventItems'));
const Auctions = React.lazy(() => import('./views2/dashboard/Design/auctions'));
const ItemsDetail = React.lazy(() => import('./views2/dashboard/Design/itemsDetail'));
const RedeemPoints = React.lazy(() => import('./views2/dashboard/Design/RedeemPoints'));
const Platforms = React.lazy(() => import('./views2/dashboard/Design/Platforms'));
const Recovery = React.lazy(() => import('./views2/auth/Login/Recovery'));
const Signup = React.lazy(() => import('./views2/auth/Login/SignUp'));
const Page404 = React.lazy(() => import('./views2/404'));
const Faq = React.lazy(() => import('./views2/dashboard/Design/Faq'));
const Aboutus = React.lazy(() => import('./views2/dashboard/Design/Aboutus'));
const Team = React.lazy(() => import('./views2/dashboard/Design/Team'));
const Stripe = React.lazy(() => import('./views2/dashboard/Design/Stripe'));
const TermAndConditions = React.lazy(() => import('./views2/dashboard/Design/TermAndConditions'));
const MarketData = React.lazy(() => import('./views2/dashboard/Design/Marketdata'));
const MemberNft = React.lazy(() => import('./views2/dashboard/Design/memberNft'));
const JoshAllen = React.lazy(() => import('./views2/dashboard/Design/joshAllen'));
const QuinnEwers = React.lazy(() => import('./views2/dashboard/Design/QuinnEwers'));
const Redeem = React.lazy(() => import('./views2/dashboard/Design/Redeem'));
const LeaderBoard = React.lazy(() => import('./views2/dashboard/Design/LeaderBoard'));
const SettingsPage = React.lazy(() => import('./views2/dashboard/Design/SettingsPage'));
const AaronDonald = React.lazy(() => import('./views2/dashboard/Design/AaronDonald'));
const RonaldAcuna = React.lazy(() => import('./views2/dashboard/Design/RonaldAcuna'));
const VladimirGuerrero = React.lazy(() => import('./views2/dashboard/Design/VladimirGuerrero'));
const ExhibitNft = React.lazy(() => import('./views2/dashboard/Design/exhibitNft'));
const GaiaMissions = React.lazy(() => import('./views2/dashboard/Design/gaia_missions'));
const AuthenticityVerifications = React.lazy(() => import('./views2/dashboard/Design/authenticityVerifications'));
const AuthenticityVerificationsDetails = React.lazy(() => import('./views2/dashboard/Design/authenticityVerificationsDetails'));
const One4One = React.lazy(() => import('./views2/dashboard/Design/one4One'));
const Sweepstakes = React.lazy(() => import('./views2/dashboard/Design/Sweepstakes'));
const Redemeptions = React.lazy(() => import('./views2/dashboard/Design/redemeptions'));
@inject('AuthStore')
@inject('UserStore')
@inject('StripeStore')
@observer
class App extends Component {
  handleSubmit = async (payment) => {
    await this.props.StripeStore.getStripe(payment);
    await this.props.AuthStore.getPreferences();
    await this.props.UserStore.getCreditCards();
    this.props.AuthStore.setModal_Popup(false);
  };
  state = {
    url: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (window.location.pathname.includes('game-used')) {
      let data = window.location.pathname.replace('game-used', 'platforms/igi/events/80');
      window.location.replace(data);
    } else if (window.location.pathname.includes('collections')) {
      let data = window.location.pathname.replace('collections', 'platforms/igi');
      window.location.replace(data);
    } else if (window.location.pathname.includes('JoshAllen')) {
      let data = window.location.pathname.replace('JoshAllen', 'platforms/igi/events/212');
      window.location.replace(data);
    } else if (window.location.pathname.includes('JoeMusgrove')) {
      let data = window.location.pathname.replace('JoeMusgrove', 'platforms/igi/events/217');
      window.location.replace(data);
    } else if (window.location.pathname.includes('BLITZ')) {
      let data = window.location.pathname.replace('BLITZ', 'platforms/igi/events/213');
      window.location.replace(data);
    } else if (window.location.pathname.includes('RonaldAcuna')) {
      let data = window.location.pathname.replace('RonaldAcuna', 'platforms/igi/events/198');
      window.location.replace(data);
    } else if (window.location.pathname.includes('VladimirGuerrero')) {
      let data = window.location.pathname.replace('VladimirGuerrero', 'platforms/igi/events/199');
      window.location.replace(data);
    } else if (window.location.pathname.includes('ShaneBieber')) {
      let data = window.location.pathname.replace('ShaneBieber', 'platforms/igi/events/216');
      window.location.replace(data);
    } else if (window.location.pathname.includes('FERNANDOTATIS')) {
      let data = window.location.pathname.replace('FERNANDOTATIS', 'platforms/igi/events/197');
      window.location.replace(data);
    } else if (window.location.pathname.includes('MikeyWilliams')) {
      let data = window.location.pathname.replace('MikeyWilliams', 'platforms/igi/events/194');
      window.location.replace(data);
    } else if (window.location.pathname.includes('QuinnEwers')) {
      let data = window.location.pathname.replace('QuinnEwers', 'platforms/igi/events/218');
      window.location.replace(data);
    } else if (window.location.pathname.includes('AnthonyRichardson')) {
      let data = window.location.pathname.replace('AnthonyRichardson', 'platforms/igi/events/219');
      window.location.replace(data);
    } else if (window.location.pathname.includes('AaronDonald')) {
      let data = window.location.pathname.replace('AaronDonald', 'platforms/igi/events/220');
      window.location.replace(data);
    }
  }
  componentWillUnmount() {
    if (this.props.AuthStore.pusher) {
      this.props.AuthStore.pusher.disconnect();
    }
  }
  componentDidMount() {
    if (window.location.href.includes('#showsignup')) {
      this.props.AuthStore.setModal_Popup_2(true);
    }
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL);
        ReactPixel.pageView();
      });
  }

  render() {
    const setting = {
      color: {
        theme: '#6abcf8',
      },
      launcher: {
        label: {
          '*': ' ',
        },
        chatLabel: {
          'en-US': 'Need Help',
        },
      },
      contactForm: {
        fields: [{ id: 'description', prefill: { '*': 'My pre-filled description' } }],
      },
    };
    return (
      <Router>
        <React.Suspense fallback={loading()}>
          <Modal isOpen={this.props.AuthStore.modal_close}>
            <ModalHeader toggle={() => this.props.AuthStore.setModal_Popup(false)} />
            {this.props.AuthStore.isAuthenticated && (
              <>
                {this.props.AuthStore.modal_name == 'shipping' && (
                  <ShippingAddress onModalClose={this.props.AuthStore.setModal_Popup} />
                )}
                {/* <PaymentCard handleSubmit={this.handleSubmit} /> */}
                {this.props.AuthStore.modal_name == 'card' && <AddCard handleSubmit={this.handleSubmit} />}
                {this.props.AuthStore.modal_name == 'edit' && <EditModal />}
              </>
            )}
            {this.props.AuthStore.modal_name == 'signin' && (
              <SignInModal onModalClose={this.props.AuthStore.setModal_Popup} />
            )}
            {this.props.AuthStore.modal_name == 'card' && (
              <AddCardInfo onModalClose={this.props.AuthStore.setModal_Popup} />
            )}
          </Modal>
          {this.props.AuthStore.modal_close_2 ? (
            <Modal isOpen={window.location.href.includes('#showsignup') == !this.props.AuthStore.isAuthenticated}>
              <ModalHeader toggle={() => this.props.AuthStore.setModal_Popup_2(false)} />
              {window.location.href.includes('#showsignup') && !this.props.AuthStore.isAuthenticated ? (
                <SignInModal onModalClose={this.props.AuthStore.setModal_Popup_2} />
              ) : null}
            </Modal>
          ) : null}
          {/* <Zendesk
            zendeskKey={ZENDESK_KEY}
            {...setting}
            // onLoaded={() => console.log("is loaded")}
          /> */}

          <Switch>
            <Route exact path="/platforms/:company_name/faq" name="Faq" render={(props) => <Faq {...props} />} />
            <Route
              exact
              path="/platforms/:company_name/Sweepstakes"
              name="Sweepstakes"
              render={(props) => <Sweepstakes {...props} />}
            />
            <Route exact path="/platforms/:company_name/team" name="Team" render={(props) => <Team {...props} />} />
            <Route
              exact
              path="/platforms/:company_name/stripe"
              name="Stripe"
              render={(props) => <Stripe {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name/Aboutus"
              name="Aboutus"
              render={(props) => <Aboutus {...props} />}
            />
            <Route exact path="/Redeem" name="Redeem" render={(props) => <Redeem {...props} />} />
            <Route exact path="/LeaderBoard" name="LeaderBoard" render={(props) => <LeaderBoard {...props} />} />
            <Route exact path="/settings" name="SettingsPage" render={(props) => <SettingsPage {...props} />} />


            <Route
              exact
              path="/josh_allen/:company_name"
              name="joshAllen"
              render={(props) => <JoshAllen {...props} />}
            />

            <Route
              exact
              path="/aaron_donald/:company_name"
              name="AaronDonald"
              render={(props) => <AaronDonald {...props} />}
            />

            <Route
              exact
              path="/quinn_ewers/:company_name"
              name="QuinnEwers"
              render={(props) => <QuinnEwers {...props} />}
            />

            <Route
              exact
              path="/member_nft/:company_name"
              name="MemberNft"
              render={(props) => <MemberNft {...props} />}
            />
            <Route
              exact
              path="/ronald_acuna/:company_name"
              name="RonaldAcuna"
              render={(props) => <RonaldAcuna {...props} />}
            />

            <Route
              exact
              path="/vladimir_guerrero/:company_name"
              name="VladimirGuerrero"
              render={(props) => <VladimirGuerrero {...props} />}
            />

            <Route exact path="/one4one/:company_name" name="One4One" render={(props) => <One4One {...props} />} />
            <Route
              exact
              path="/exhibit_nft/:company_name"
              name="ExhibitNft"
              render={(props) => <ExhibitNft {...props} />}
            />
            <Route
              exact
              path="/gaia_missions/:company_name"
              name="GaiaMissions"
              render={(props) => <GaiaMissions {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name/terms_and_conditions"
              name="TermAndConditions"
              render={(props) => <TermAndConditions {...props} />}
            />
            <Route exact path="/events/:id/:api" name="ItemsDetail" render={(props) => <ItemsDetail {...props} />} />

            <Route
              exact
              path="/platforms/:company_name/marketplace"
              name="MarketPlace"
              render={(props) => <MarketPlace {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name/giveaways"
              name="GiveAwayItems"
              render={(props) => <GiveAwayItems {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name/items/:item_type_id/:event_item_id"
              name="Auctions"
              render={(props) => <Auctions {...props} />}
            />
              <Route
              exact
              path="/redeem_points/items/:item_type_id/:event_item_id"
              name="RedeemPointsItems"
              render={(props) => <Auctions {...props} points={true} />}
            />

            <Route
              exact
              path="/platforms/:company_name"
              name="Platforms"
              render={(props) => <Platforms {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name"
              name="Platforms"
              render={(props) => <Platforms {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name/events/:id"
              name="ItemsDetail"
              render={(props) => <ItemsDetail {...props} />}
            />
            <Route
              exact
              path="/redeem_points"
              name="RedeemPoints"
              render={(props) => <RedeemPoints {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name/redemeptions"
              name="Redemeptions"
              render={(props) => <Redemeptions {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name/items/:item_type_id/:event_item_id/:marketplace"
              name="Auctions"
              render={(props) => <Auctions {...props} />}
            />
            <Route
              exact
              path="/items/:item_type_id/:event_item_id"
              name="Auctions"
              render={(props) => <Auctions {...props} />}
            />
            <Route exact path="/design/event_items" name="EventItems" render={(props) => <EventItems {...props} />} />

            <Route
              exact
              path="/featured_events"
              name="FeaturedEvents"
              render={(props) => <FeaturedEvents {...props} />}
            />
            

            <Route
              exact
              path="/verify-authenticity"
              name="AuthenticityVerificatios"
              render={(props) => <AuthenticityVerifications {...props} />}
            />
            
            <Route
            exact
            path="/verification-details"
            name="AuthenticityVerificationsDetails"
            render={(props) => <AuthenticityVerificationsDetails {...props} />}
           />

            <Route
              exact
              path="/analytics/:company_name"
              name="MarketData"
              render={(props) => <MarketData {...props} />}
            />

            {/* {process.env.REACT_APP_ENVIRONMENT == "production" ? (
              <Route exact path="/" name="Design Page" component={() => window.location.href = "https://igotitapp.com"} />
            ) : null} */}
            <Route exact path="/" name="Design Page" render={(props) => <Design {...props} />} />
            <Route exact path="/signup" name="SignUp Page" render={(props) => <Signup {...props} />} />
            <Route exact path="/recovery" name="Recovery" render={(props) => <Recovery {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
            {/* <PrivateRoute  path="/sources/create" name="Sources Create" component={SourcesCreate}/>
            <PrivateRoute  path="/sources" name="Sources Index" component={SourcesIndex}/> */}
            <Redirect to="/" />
            {/* <PrivateRoute  path="/sources/create" name="Sources Create" render={props => <SourcesIndex {...props} />}
            <PrivateRoute  path="/sources" name="Sources Index"  render={props => <SourcesIndex {...props} />} */}
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
