import { observable, action } from 'mobx';
import { post, get, deleted } from '../services/ajax.service';
import setAuthToken from '../utils/setAuthToken';
import isEmpty from '../utils/isEmpty';
import { createContext } from 'react';
import { CompanyUrlName } from '../Validators/CompanyUrlName.validator';
import Pusher from 'pusher-js';

class AuthStore {
  @observable user = {};
  @observable totalpoints = {};
  @observable email_sent = false;
  @observable dark_model_toggle = JSON.parse(localStorage.getItem('dark_mode')) ?? false;
  @observable loading = false;
  @observable action = false;
  @observable isAuthenticated = false;
  @observable error = '';
  @observable errorS = {};
  @observable errorL = {};
  @observable modal_close = false;
  @observable modal_close_2 = false;
  @observable modal_name = '';
  @observable company_url_name = 'igi';
  @observable pusher = '';
  @observable message = '';

  @observable redeem_current = 0;
  @observable redeem_current_state =0;

  @action setCompanyUrlName = (value = 'igi') => {
    this.company_url_name = CompanyUrlName(value);
  };
  @action setModal_Popup_2 = (value = false) => {
    this.modal_close_2 = value;
  };
  @action setModal_Popup = (value = false) => {
    this.modal_close = value;
  };
  @action setModal_name = (value) => {
    this.modal_name = value;
    this.modal_close = true;
  };

  @action setDefaults = () => {
    this.email_sent = false;
  };
  @action setAction = (action) => {
    this.action = action;
  };

  @action ToggleDarkMode = () => {
    this.dark_model_toggle = !this.dark_model_toggle;
    localStorage.setItem('dark_mode', this.dark_model_toggle);
  };

  @action getPreferences = async (token) => {
    try {
      const user = await get('/users/profile.json');
      this.setUser(user.data.user);
    } catch (e) {
      this.errors = e.response.data;
    }
  };

  @action Transfer_credentials = async (data) => {
    this.loading = true;
    this.error = '';
    this.errorL = {};
    try {
      const response = await post(`/users/login.json`, data);
      if (response.data) {
        this.setUser(response.data.user);
        this.errorL = response.data;
        return;
      }
      this.errorL = response.data;
      return response;
    } catch (err) {
      this.error = err.response.data.errors[0];
      return err;
    }
  };
  @action Total_points = async (data) => {
    console.log(data);
    this.error = '';
    try {
      let totalPoints = await get(`/users/users_loyalty_points.json?access_token=${data.access_token}&api_key=${data.api_key}&start_date=${data.start_date}&end_date=${data.end_date}`);

      this.error = totalPoints.data.message;

      return totalPoints;
    } catch (err) {
      this.error = err.response?.data?.errors[0];
    }
  };
  
  @action Gift_password_check = async (data) => {
    this.loading = true;
    this.error = '';
    this.errorL = {};
    try {
      const response = await post(`/users/login.json`, data);
      if (response.data) {
        this.setUser(response.data.user);
        this.errorL = response.data;
        return;
      }
      this.errorL = response.data;
      return response;
    } catch (err) {
      this.error = err.response.data.errors[0];
      return err;
    }
  };

  @action UpdateUser = async (data, history) => {
    this.error = '';
    this.errors = '';
    this.errorL = {};
    this.message = '';
    try {
      const response = await post(`/users/update.json`, data);
      this.action = true;
      this.message = response.data;
      // window.location.reload('/');
    } catch (e) {
      this.errors = e.response.data;
    }
  };

  @action login = async (data) => {
    this.loading = true;
    this.error = '';
    this.errorL = {};
    try {
      const login = await post(`/users/login.json`, data);
      if (login.data) {
        const { access_token } = login.data.user;
        setAuthToken(access_token, login.data.user.id);
        localStorage.setItem('access_token', access_token);
        this.setUser(login.data.user);
        this.errorL = login.data;
        return "success";
        // history.push("/");
      }
      this.errorL = login.data;
      return login;
    } catch (err) {
      this.error = err.response.data.errors[0];
      return err.response.data.errors[0];
    }
  };

  @action signup = async (data) => {
    this.loading = true;
    this.error = '';
    this.errorS = {};
    try {
      // this.error={};
      const signup = await post(`/users/signup.json`, data);

      this.errorS = signup.data;
    } catch (err) {
      if (err.response.data.error) {
        this.error = err.response.data.error;
        return err;
      }
      if (err.response.data.errors[0]) {
        this.error = err.response.data.errors[0];
        return err;
      }
    }
    this.loading = false;
  };

  @action CareerApplications = async (data) => {
    this.loading = true;
    this.error = '';
    try {
      await post(`/careers/new.json`, data);
    } catch (err) {
      if (err.response.data.error) {
        this.error = err.response.data.error;
        return err;
      }
      if (err.response.data.errors[0]) {
        this.error = err.response.data.errors[0];
        return err;
      }
    }
    this.loading = false;
  };

  @action delete = async (data) => {
    this.loading = true;
    this.error = '';
    this.errorS = {};
    try {
      // this.error={};
      const deLete = await deleted(`/users/delete_user_account.json`, data);
      this.message = deLete.data.message;
    } catch (err) {
      if (err.response.data.error) {
        this.error = err.response.data.error;
        return err;
      }
      if (err.response.data.errors) {
        this.error = err.response.data.errors[0];
        return err;
      }
    }
    this.loading = false;
  };

  @action emailEnable = async (data) => {
    console.log(data)
    this.loading = true;
    this.error = '';
    this.errorS = {};
    try {
      // this.error={};
      const eNable = await post(`/users/enable_disable_notifications.json`, data);
      this.message = eNable.data.user.emails_enabled;
      console.log(eNable.data.user.emails_enabled)
    } catch (err) {
      if (err.response.data.error) {
        this.error = err.response.data.error;
        return err;
      }
      if (err.response.data.errors) {
        this.error = err.response.data.errors[0];
        return err;
      }
    }
    this.loading = false;
  };

  @action CareerApplications = async (data) => {
    this.loading = true;
    this.error = '';
    try {
      await post(`/careers/new.json`, data);
    } catch (err) {
      if (err.response.data.error) {
        this.error = err.response.data.error;
        return err;
      }
      if (err.response.data.errors[0]) {
        this.error = err.response.data.errors[0];
        return err;
      }
    }
    this.loading = false;
  };

  @action signup_with_google = async (data) => {
    this.error = '';
    this.errorS = {};
    try {
      // this.error={};
      const signup = await post(`/users/gmail_signup.json`, data);
      if (signup.data) {
        const { access_token } = signup.data.user;
        setAuthToken(access_token, signup.data.user.id);
        localStorage.setItem('access_token', access_token);
        this.setUser(signup.data.user);
        this.errorS = signup.data;
        return;
        // history.push("/");
      }
      this.errorS = signup.data;
    } catch (err) {
      if (err.response.data.error) {
        this.error = err.response.data.error;
        return err;
      }
      if (err.response.data.errors[0]) {
        this.error = err.response.data.errors[0];
        return err;
      }
    }
  };

  @action recovery = async (email, history) => {
    this.error = '';
    try {
      let recovery = await get(`/users/reset_password.json?email=${email}`);

      this.error = recovery.data.message;

      return recovery;
    } catch (err) {
      this.error = err.response.data.errors[0];
    }
  };

  @action logout(company_name,redeem) {
    localStorage.removeItem('access_token');
    setAuthToken(false);

    
       if (company_name && !redeem) {
      window.location.href = `/platforms/${company_name}`;
      return; }
      window.location.href = '/';
    
   
  }


  @action setUser(data) {
    this.isAuthenticated = !isEmpty(data);
    if (!isEmpty(data)) {
      this.user = data;
      this.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY);
    }
  }
}

export default AuthStore;
export const AuthStoreContext = createContext(new AuthStore());
