import axios from 'axios'
require("dotenv").config();

const axiosInstance = axios.create({
baseURL:
window.location.pathname.split('/').pop() == 'bengals'
? process.env.REACT_APP_BASE_URL_BENGALS
: window.location.pathname.split('/').pop() == 'pll'
? process.env.REACT_APP_BASE_URL_PLL
: window.location.pathname.split('/').pop() == 'demo'
? process.env.REACT_APP_BASE_URL_DEMO
: window.location.pathname.split('/').pop() == 'pelicans'
? process.env.REACT_APP_BASE_URL_PELICANS
: window.location.pathname.split('/').pop() == 'pfhof'
? process.env.REACT_APP_BASE_URL_PFHOF
: window.location.pathname.split('/').pop() == 'bucks'
? process.env.REACT_APP_BASE_URL_BUCKS
: window.location.pathname.split('/').pop() == 'saints'
? process.env.REACT_APP_BASE_URL_CAVALIERS
: window.location.pathname.split('/').pop() == 'pistons'
? process.env.REACT_APP_BASE_URL_PISTONS
: window.location.pathname.split('/').pop() == 'chiefs'
? process.env.REACT_APP_BASE_URL_CHARGERS
: window.location.pathname.split('/').pop() == 'kings'
? process.env.REACT_APP_BASE_URL_KINGS
: window.location.pathname.split('/').pop() == 'jaguars'
? process.env.REACT_APP_BASE_URL_JAGUARS
: window.location.pathname.split('/').pop() == 'ravens'
? process.env.REACT_APP_BASE_URL_RAVENS
: window.location.pathname.split('/').pop() == 'koops'
? process.env.REACT_APP_BASE_URL_KOOPS
: window.location.pathname.split('/').pop() == 'texans'
? process.env.REACT_APP_BASE_URL_TEXANS
: window.location.pathname.split('/').pop() == 'falcons'
? process.env.REACT_APP_BASE_URL_FALCONS
: window.location.pathname.split('/').pop() == 'rams'
? process.env.REACT_APP_BASE_URL_RAMS
: window.location.pathname.split('/').pop() == 'nll'
? process.env.REACT_APP_BASE_URL_NLL
: window.location.pathname.split('/').pop() == 'colts'
? process.env.REACT_APP_BASE_URL_COLTS
: window.location.pathname.split('/').pop() == 'bills'
? process.env.REACT_APP_BASE_URL_BILLS
: window.location.pathname.split('/').pop() == 'dcbids'
? process.env.REACT_APP_BASE_URL_DCBIDS
: window.location.pathname.split('/').pop() == 'bucsbids'
? process.env.REACT_APP_BASE_URL_BUCSBIDS
: process.env.REACT_APP_BASE_URL,
headers:
{
    "Api-Key":
    window.location.pathname.split('/').pop() == 'bengals'
    ? process.env.REACT_APP_API_KEY_BENGALS
    : window.location.pathname.split('/').pop() == 'pll'
    ? process.env.REACT_APP_API_KEY_PLL
    : window.location.pathname.split('/').pop() == 'demo'
    ? process.env.REACT_APP_API_KEY_DEMO
    : window.location.pathname.split('/').pop() == 'pelicans'
    ? process.env.REACT_APP_API_KEY_PELICANS
    : window.location.pathname.split('/').pop() == 'pfhof'
    ? process.env.REACT_APP_API_KEY_PFHOF
    : window.location.pathname.split('/').pop() == 'bucks'
    ? process.env.REACT_APP_API_KEY_BUCKS
    : window.location.pathname.split('/').pop() == 'saints'
    ? process.env.REACT_APP_API_KEY_CAVALIERS
    : window.location.pathname.split('/').pop() == 'pistons'
    ? process.env.REACT_APP_API_KEY_PISTONS
    : window.location.pathname.split('/').pop() == 'chiefs'
    ? process.env.REACT_APP_API_KEY_CHARGERS
    : window.location.pathname.split('/').pop() == 'kings'
    ? process.env.REACT_APP_API_KEY_KINGS
    : window.location.pathname.split('/').pop() == 'jaguars'
    ? process.env.REACT_APP_API_KEY_JAGUARS
    : window.location.pathname.split('/').pop() == 'ravens'
    ? process.env.REACT_APP_API_KEY_RAVENS
    : window.location.pathname.split('/').pop() == 'koops'
    ? process.env.REACT_APP_API_KEY_KOOPS
    : window.location.pathname.split('/').pop() == 'texans'
    ? process.env.REACT_APP_API_KEY_TEXANS
    : window.location.pathname.split('/').pop() == 'falcons'
    ? process.env.REACT_APP_API_KEY_FALCONS
    : window.location.pathname.split('/').pop() == 'rams'
    ? process.env.REACT_APP_API_KEY_RAMS
    : window.location.pathname.split('/').pop() == 'nll'
    ? process.env.REACT_APP_API_KEY_NLL
    : window.location.pathname.split('/').pop() == 'colts'
    ? process.env.REACT_APP_API_KEY_COLTS
    : window.location.pathname.split('/').pop() == 'bills'
    ? process.env.REACT_APP_API_KEY_BILLS
    : window.location.pathname.split('/').pop() == 'dcbids'
    ? process.env.REACT_APP_API_KEY_DCBIDS
    : window.location.pathname.split('/').pop() == 'bucsbids'
    ? process.env.REACT_APP_API_KEY_BUCSBIDS
    : process.env.REACT_APP_API_KEY,
    "Os" : "Web"
}
})

// axiosInstance.interceptors.request.use(function (config) {
//     //config.data = {...config.data, ...{"api_key": process.env.REACT_APP_API_KEY}}
//     config.headers= {...config.headers, ...{"Api-Key": process.env.REACT_APP_API_KEY}}

//     return config
// }, function (err) {
//   return Promise.reject(err)
// }
// )

export default axiosInstance;

