const ListingPageHelper = (value) => {


  

  if (value == "Buy Only") {
    return "VIEW";
  }
  if (value == "Bid Only") {
    return "VIEW";
  }
  if (value == "Auction") {
    return "PENDING";
  }
  if (value == "Raffle") {
    return "VIEW";
  }
  if (value == "Discount") {
    // return "VIEW";
    return "CLAIM YOUR DISCOUNT";
  }
  return "VIEW"
}
export { ListingPageHelper }