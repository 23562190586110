import { get } from "../services/ajax.service"

async function fetch_company_details(url, api_key, company_name, enforce) {
    if (process.env.REACT_APP_ENVIRONMENT == "production") {
        // if(sessionStorage.getItem("company_name") && sessionStorage.getItem("company_details") && sessionStorage.getItem("company_name") == company_name) {
        //     return {company_data:JSON.parse(sessionStorage.getItem("company_details")), url,api_key}
        // }
    }
    let company_details = await get(`${url}/companies/get_company.json`, { headers: { "Api-Key": api_key } });
    sessionStorage.setItem("company_details", JSON.stringify(company_details.data));
    return { company_data: company_details.data, url, api_key }
}



export default async function (company_name = "igi", enforce = false) {
    company_name = company_name.toLocaleLowerCase();
    switch (company_name) {
        case "igi":
            return fetch_company_details(process.env.REACT_APP_BASE_URL, process.env.REACT_APP_API_KEY, company_name, enforce)
        case "pll":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_PLL, process.env.REACT_APP_API_KEY_PLL, company_name, enforce)
        case "demo":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_DEMO, process.env.REACT_APP_API_KEY_DEMO, company_name, enforce)
        case "pelicans":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_PELICANS, process.env.REACT_APP_API_KEY_PELICANS, company_name, enforce)
        case "pfhof":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_PFHOF, process.env.REACT_APP_API_KEY_PFHOF, company_name, enforce)
        case "bucks":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_BUCKS, process.env.REACT_APP_API_KEY_BUCKS, company_name, enforce)
        case "saints":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_SAINTS, process.env.REACT_APP_API_KEY_SAINTS, company_name, enforce)
        case "cavaliers":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_CAVALIERS, process.env.REACT_APP_API_KEY_CAVALIERS, company_name, enforce)
        case "pistons":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_PISTONS, process.env.REACT_APP_API_KEY_PISTONS, company_name, enforce)
        case "chiefs":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_CHIEFS, process.env.REACT_APP_API_KEY_CHIEFS, company_name, enforce)
        case "chargers":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_CHARGERS, process.env.REACT_APP_API_KEY_CHARGERS, company_name, enforce)
        case "kings":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_KINGS, process.env.REACT_APP_API_KEY_KINGS, company_name, enforce)
        case "jaguars":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_JAGUARS, process.env.REACT_APP_API_KEY_JAGUARS, company_name, enforce)
        case "bengals":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_BENGALS, process.env.REACT_APP_API_KEY_BENGALS, company_name, enforce)
        case "ravens":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_RAVENS, process.env.REACT_APP_API_KEY_RAVENS, company_name, enforce)
        case "koops":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_KOOPS, process.env.REACT_APP_API_KEY_KOOPS, company_name, enforce)
        case "texans":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_TEXANS, process.env.REACT_APP_API_KEY_TEXANS, company_name, enforce)
        case "falcons":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_FALCONS, process.env.REACT_APP_API_KEY_FALCONS, company_name, enforce)
        case "rams":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_RAMS, process.env.REACT_APP_API_KEY_RAMS, company_name, enforce)
        case "nll":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_NLL, process.env.REACT_APP_API_KEY_NLL, company_name, enforce)
        case "colts":
          return fetch_company_details(process.env.REACT_APP_BASE_URL_COLTS, process.env.REACT_APP_API_KEY_COLTS, company_name, enforce)
        case "bills":
          return fetch_company_details(process.env.REACT_APP_BASE_URL_BILLS, process.env.REACT_APP_API_KEY_BILLS, company_name, enforce)
        case "dcbids":
          return fetch_company_details(process.env.REACT_APP_BASE_URL_DCBIDS, process.env.REACT_APP_API_KEY_DCBIDS, company_name, enforce)
          case "bucsbids":
            return fetch_company_details(process.env.REACT_APP_BASE_URL_BUCSBIDS, process.env.REACT_APP_API_KEY_BUCSBIDS, company_name, enforce)
        default:
            return fetch_company_details(process.env.REACT_APP_BASE_URL, process.env.REACT_APP_API_KEY, window.location.pathname.split('/').pop(), enforce)
    }
}
