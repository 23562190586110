
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import moment from 'moment'

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      giveawayitem: false,
    };
  }
  // componentWillMount() {
  //   this.getTimeUntil(this.props.deadline);
  // }

  componentDidMount() {
    this.setState({giveawayitem: this.props.giveawayitem});
    setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
  }

  leading0(num) {
    return `${num < 10 ? '0' : ''}${num}`;
  }
  pluralize(data) {
    data = parseInt(data);
    if (data > 1) {
      return "s"
    }
    return ""
  }

  getTimeUntil(deadline) {
    let utc = new Date().toISOString()
    const time = moment(deadline) - (moment(utc));
    let deadlinedate = moment(deadline);
   this.setState({deadline: deadlinedate.format('MMM DD, YYYY')})
    if (time < 0) {
      this.setState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.setState({ days, hours, minutes, seconds });
    }
  }

  render() {
    if (isNaN(this.state.days)) return null;

    return (
      <div className="reversespan" style={{ ...this.props.style, maxWidth: '225px' }}>
        {this.props?.status == 'sold' ? (
        <div className="Clock-days text-danger" style={{ fontSize: "16px" }}>Sold</div>
        ) : 
        +this.state.days + +this.state.hours + +this.state.minutes + +this.state.seconds === 0 ? (
          this.state.giveawayitem ? (
          <div className="Clock-days text-danger" style={{ fontSize: "16px" }}>Closed</div>
          ):(
          <div className="Clock-days text-danger" style={{ fontSize: "16px" }}>Sold</div>
          )
        ) : (
          this.state.giveawayitem ? (
            <span className="reversespan">{this.state.deadline} </span>
            ) : (
          <>
            <div className="Clock-days" style={{ fontSize: "16px" }}>
              {this.leading0(this.state.days) != 0 ? (
                <>
                  {(this.state.days == 0 && this.state.hours == 0 && this.state.minutes < 1) ? (
                    <span style={{ color: "red" }}>{this.leading0(this.state.days)} day{this.pluralize(this.state.days)} </span>
                  )
                    :
                    <span className="reversespan">{this.leading0(this.state.days)} day{this.pluralize(this.state.days)} </span>
                  }
                </>
              ) : null
              }
              {this.leading0(this.state.hours) != 0 ? (
                <>
                  {(this.state.days == 0 && this.state.hours == 0 && this.state.minutes < 1) ? (
                    <span style={{ color: "red" }}>{this.leading0(this.state.hours)} hr{this.pluralize(this.state.hours)} </span>
                  )
                    :
                    <span className="reversespan">{this.leading0(this.state.hours)} hr{this.pluralize(this.state.hours)} </span>
                  }

                </>
              ) : null
              }

              {this.leading0(this.state.days) == 0 ? (
                <>
                  {(this.state.days == 0 && this.state.hours == 0 && this.state.minutes < 1) ? (
                    <span style={{ color: "red" }}>{this.leading0(this.state.minutes)} min{this.pluralize(this.state.minutes)} </span>
                  )
                    :
                    <span style={{  }}>{this.leading0(this.state.minutes)} min{this.pluralize(this.state.minutes)} </span>
                  }

                </>
              ) : null
              }

              {this.leading0(this.state.days) == 0 && this.leading0(this.state.hours) == 0 ? (
                <>
                  {(this.state.days == 0 && this.state.hours == 0 && this.state.minutes < 1) ? (
                    <span style={{ color: "red" }}>{this.leading0(this.state.seconds)} sec{this.pluralize(this.state.seconds)} </span>
                  )
                    :
                    <span style={{ }}>{this.leading0(this.state.seconds)} sec{this.pluralize(this.state.seconds)} </span>
                  }

                </>
              ) : null
              }

            </div>
          </>
            )
        )

        }
      </div>
    );
  }
}
export default CountDown;
