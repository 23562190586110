const SetThemeColor = (theme_color) => {
    if (theme_color === "black") {
      var rootBgs = document.getElementsByClassName('rootbg');
      for (var i = 0; i < rootBgs.length; i++) {
        rootBgs[i].style.backgroundColor = `#272727`;
      }
      var antdTablehead = document.querySelectorAll('.ant-table-thead tr th');
      for (var i = 0; i < antdTablehead.length; i++) {
        antdTablehead[i].style.backgroundColor = `black`;
        antdTablehead[i].style.textAlign = `center`;
        antdTablehead[i].style.borderColor = `white`;
      }
      var antdTablebody = document.querySelectorAll('.ant-table-tbody tr td');
      for (var i = 0; i < antdTablebody.length; i++) {
        antdTablebody[i].style.backgroundColor = `#272727`;
        antdTablebody[i].style.textAlign = `center`;
      }
      var antdTablebodytr = document.querySelectorAll('tr.ant-table-row.ant-table-row-level-0');
      if (antdTablebodytr) {
        Array.from(antdTablebodytr).forEach((item) => {
          item.addEventListener('mouseover', () => {
            item.style.setProperty('background-color', '#17191a', 'important');
          });

          item.addEventListener('mouseout', () => {
            item.style.setProperty('background-color', '#272727', 'important');
          });
        });
      }
      var imgBgs = document.getElementsByClassName('imgbg');
      for (var i = 0; i < imgBgs.length; i++) {
        imgBgs[i].style.backgroundColor = `black`;
      }
      var modalcontent = document.getElementsByClassName('modal-content');
      for (var i = 0; i < modalcontent.length; i++) {
        modalcontent[i].style.backgroundColor = `2C2C2C`;
      }
      var reverseSpan = document.getElementsByClassName('reversespan');
      for (var i = 0; i < reverseSpan.length; i++) {
        reverseSpan[i].style.color = `#fff`;
      }
      var headingsAndParagraphs = document.querySelectorAll(
        'h1, h2, h3, h4, h5, h6, p, a, i, li, label, span, p, th, tr, u, .themebtn',
      );
      for (var i = 0; i < headingsAndParagraphs.length; i++) {
        var currentElement = headingsAndParagraphs[i];
        currentElement.style.color = '#fff';
      }
      var elements = document.querySelectorAll('.filter-img'); // Replace '.your-class-name' with the actual class name you want to target

      for (var i = 0; i < elements.length; i++) {
        var currentElement = elements[i];
        currentElement.style.filter = 'invert(1)';
      }

      var reverseBgs = document.getElementsByClassName('reversebg');
      for (var i = 0; i < reverseBgs.length; i++) {
        reverseBgs[i].style.backgroundColor = '#2C2C2C';
      }
      var btnBgs = document.getElementsByClassName('blkthemebtn');
      for (var i = 0; i < btnBgs.length; i++) {
        btnBgs[i].style.backgroundColor = '#fff';
        btnBgs[i].style.color = '#000';
        btnBgs[i].style.border = '0px';
      }
       var btnviewBgs = document.getElementsByClassName('viewbtn');
       for (var i = 0; i < btnviewBgs.length; i++) {
         btnviewBgs[i].style.backgroundColor = '#fff';
         btnviewBgs[i].style.color = '#000';
       }
      var dynamicTextColors = document.querySelectorAll('.dynamicTextColors p');
      for (var i = 0; i < dynamicTextColors.length; i++) {
        dynamicTextColors[i].style.color = '#fff !important';
      }
      // var antmodelBgs = document.getElementsByClassName('ant-steps-label-horizontal');
      // for (var i = 0; i < antmodelBgs.length; i++) {
      //   antmodelBgs[i].style.backgroundColor = '#E7E0EC';
      //   antmodelBgs[i].style.color = '#000';
      // }
      // var antmodelBtn = document.getElementsByClassName('ant-btn-submit');
      // for (var i = 0; i < antmodelBtn.length; i++) {
      //   antmodelBtn[i].style.backgroundColor = '#E7E0EC';
      //   antmodelBtn[i].style.color = '#000';
      // }
  



    } 
    else {
        var rootBgs = document.getElementsByClassName('rootbg');
        for (var i = 0; i < rootBgs.length; i++) {
          rootBgs[i].style.backgroundColor = `${theme_color}`;
        }
        var antdTablehead = document.querySelectorAll('.ant-table-thead tr th');
      for (var i = 0; i < antdTablehead.length; i++) {
        antdTablehead[i].style.backgroundColor = `#CACACA`;
        antdTablehead[i].style.textAlign = `center`;
        antdTablehead[i].style.borderColor = `black`;
      }
      var antdTablebody = document.querySelectorAll('.ant-table-tbody tr td');
      for (var i = 0; i < antdTablebody.length; i++) {
        antdTablebody[i].style.backgroundColor = `#f2f2f2`;
        antdTablebody[i].style.textAlign = `center`;
      }
      var antdTablebodytr = document.querySelectorAll('tr.ant-table-row.ant-table-row-level-0');
      if (antdTablebodytr) {
        Array.from(antdTablebodytr).forEach((item) => {
          item.addEventListener('mouseover', () => {
            item.style.setProperty('background-color', '#CACACA', 'important');
          });

          item.addEventListener('mouseout', () => {
            item.style.setProperty('background-color', '#f2f2f2', 'important');
          });
        });
      }
        var imgBgs = document.getElementsByClassName('imgbg');
        for (var i = 0; i < imgBgs.length; i++) {
          imgBgs[i].style.backgroundColor = `${theme_color}`;
        }
        var modalcontent = document.getElementsByClassName('modal-content');
        for (var i = 0; i < modalcontent.length; i++) {
          modalcontent[i].style.backgroundColor = `#CACACA`;
        }

        var headingsAndParagraphs = document.querySelectorAll(
          'h1, h2, h3, h4, h5, h6, p, a, i ,li, label, span, p, th, tr, u, .themebtn',
        );
        for (var i = 0; i < headingsAndParagraphs.length; i++) {
          var currentElement = headingsAndParagraphs[i];
          currentElement.style.color = '#252525';
         
        }
        var elements = document.querySelectorAll('.filter-icon'); // Replace '.your-class-name' with the actual class name you want to target

        for (var i = 0; i < elements.length; i++) {
          var currentElement = elements[i];
          currentElement.style.filter = 'invert(0)';
        }
        var reverseBgs = document.getElementsByClassName('reversebg');
        for (var i = 0; i < reverseBgs.length; i++) {
          reverseBgs[i].style.backgroundColor = '#CACACA';
        }
        var reverseSpan = document.getElementsByClassName('reversespan');
        for (var i = 0; i < reverseSpan.length; i++) {
          reverseSpan[i].style.color = `#252525`;
        }
        var btnBgs = document.getElementsByClassName('blkthemebtn');
        for (var i = 0; i < btnBgs.length; i++) {
          btnBgs[i].style.backgroundColor = '#CACACA';
          btnBgs[i].style.color = '#000';
          btnBgs[i].style.border = '0px';
        }
        var btnviewBgs = document.getElementsByClassName('viewbtn');
        for (var i = 0; i < btnviewBgs.length; i++) {
          btnviewBgs[i].style.backgroundColor = '#000';
          btnviewBgs[i].style.color = '#fff';
        }
       var dynamicTextColors = document.querySelectorAll('.dynamicTextColors p');
       for (var i = 0; i < dynamicTextColors.length; i++) {
         dynamicTextColors[i].style.color = '#000';
       }
       var pagination = document.querySelectorAll('#page-numbers li');
       for (var i = 0; i < pagination.length; i++) {
        pagination[i].style = 'border-color: rgba(0,0,0,0.6)';
      }
      var paginationHover = document.querySelectorAll('#page-numbers li');
      if (paginationHover) {
        Array.from(paginationHover).forEach((item) => {
          item.addEventListener('mouseover', () => {
            item.style.setProperty('background-color', 'rgba(0,0,0,0.6)', 'important');
            item.style.setProperty('color', 'white', 'important');
          });

          item.addEventListener('mouseout', () => {
            item.style.setProperty('background-color', 'transparent');
            item.style.setProperty('color', 'black');
          });
        });
      }
      var paginationActive = document.querySelectorAll('.pagination-number-active');
      for (var i = 0; i < paginationActive.length; i++) {
        paginationActive[i].style.setProperty('background-color', 'rgba(0,0,0,0.6)', 'important');
        paginationActive[i].style.setProperty('color', 'white', 'important');
        Array.from(paginationActive).forEach((item) => {
          item.addEventListener('mouseover', () => {
            item.style.setProperty('background-color', 'rgba(0,0,0,0.6)', 'important');
            item.style.setProperty('color', 'white', 'important');
          });

          item.addEventListener('mouseout', () => {
            item.style.setProperty('background-color', 'rgba(0,0,0,0.6)', 'important');
            item.style.setProperty('color', 'white', 'important');
          });
        });
      }
      var paginationDisabled = document.querySelectorAll('.pagination-disabled');
        Array.from(paginationDisabled).forEach((item) => {
          item.addEventListener('mouseover', () => {
            item.style.setProperty('background-color', 'transparent', 'important');
            item.style.setProperty('color', 'black', 'important');
          });
        });
        // var antmodelBgs = document.getElementsByClassName('ant-steps-label-horizontal');
        //  for (var i = 0; i < antmodelBgs.length; i++) { 
        //   antmodelBgs[i].style.backgroundColor = '#E7E0EC';
        //   antmodelBgs[i].style.color = '#000';
        // }
        // var antmodelBtn = document.getElementsByClassName('ant-btn-submit');
        // for (var i = 0; i < antmodelBtn.length; i++) {
        //   antmodelBtn[i].style.backgroundColor = '#E7E0EC';
        //   antmodelBtn[i].style.color = '#000';
        // }
    


}
  
};

export default SetThemeColor;
