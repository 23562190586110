const ButtonTitleHelper = (value) => {

  if (value == "Buy Only") {
    return "BUY";
  }
  if (value == "Bid Only") {
    return "BID";
  }
  if (value == "Auction") {
    return "PENDING";
  }
  if (value == "Raffle") {
    return "ENTER";
  }
  if (value == "Discount") {
    return "CLAIM YOUR DISCOUNT";
  }
  return "BUY"
}
export { ButtonTitleHelper }