
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App2 from "./App2"
import store from "./store";
import { Provider } from "mobx-react";
import setAuthToken from "./utils/setAuthToken";
import * as serviceWorker from "./serviceWorker";


const run = async () => {
  const rootStore = new store();
  if (localStorage.access_token) {

    try {
      setAuthToken(localStorage.access_token);
      const decoded = localStorage.access_token;

      await rootStore.AuthStore.getPreferences(decoded)
      setAuthToken(localStorage.access_token, rootStore.AuthStore.user.id);
    } catch (err) {
      localStorage.removeItem("access_token");
      window.location.href = "/";
    }
  }

  const Root = (
    <Provider
      AuthStore={rootStore.AuthStore}
      EntityStore={rootStore.EntityStore}
      LabelStore={rootStore.LabelStore}
      ReportStore={rootStore.ReportStore}
      EventStore={rootStore.EventStore}
      WishlistStore={rootStore.WishlistStore}
      UserStore={rootStore.UserStore}
      BidStore={rootStore.BidStore}
      StripeStore={rootStore.StripeStore}
      RequestStore={rootStore.RequestStore}
      CompanyStore={rootStore.CompanyStore}
      MarketPlace={rootStore.MarketPlace}
      RaffleStore={rootStore.RaffleStore}
      AthletesStore={rootStore.AthletesStore}
    >
      <App2 />
    </Provider>
  );
  ReactDOM.render(Root, document.getElementById("root"));
  serviceWorker.unregister();
}


run()

