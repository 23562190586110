import { observable, action } from "mobx";
import { get } from "../services/ajax.service";

class AthletesStore {

    @observable athletes = [];
    @observable errors = "";

    @action getAthletes = async (data,options={}) => {
        this.athletes = [];
        this.errors = "";
        try {
            let response = await get(
                `/athletes/featured_athletes.json`, data,options
            );
            this.errors="";
             this.athletes = response.data.featured_ahtletes;
        } catch (err) {
            console.log(err.response.data.errors[0])
            this.errors = err.response.data.errors[0]
        }
    }

}

export default AthletesStore;
