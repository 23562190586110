const EventItemId = (value) => {
    
    if(value=="Buy Only"){
      return 1;
    }
    if(value=="Bid Only"){
      return 2;
    }
    if(value=="Auction"){
      return 5;
    }
    if(value=="Raffle"){
      return 6;
    }
    if(value=="Discount"){
      return 7;
    }
    if(value=="Gift"){
      return 8;
    }
}
export {EventItemId}