import { observable, action } from "mobx";
import { get } from "../services/ajax.service";
import { createContext } from 'react';

class CompanyStore {

  @observable companies=[];
  @observable companies_featured=[];
  @observable faqs =null;
  @observable termandconditions= null;
  @observable company={};
  @observable market_place=false;





  @action get_companies_featured = async () => {
    this.companies_featured=[];
      try {
        let response=  await get(`/companies/get_companies.json?is_featured=true`);
       
        if(response.data.companies.length >0 ){
          response.data.companies.sort(function (a, b) {
            return a.order - b.order;
          });

        }
        this.companies_featured=response.data.companies;
      }
      catch (err) {
          console.log(err, "error");
      }
  };



  @action get_companies_faq = async (options={}) => {
    this.faq=null;
      try {

        let response=  await get(`/companies/get_company_faqs.json`);
        this.faq=response.data.faqs;
      }
      catch (err) {
          console.log(err, "error");
      }
  };

  @action get_companies_term_and_conditions = async (options={}) => {
    this.termandconditions=null;
      try {

        let response=  await get(`/companies/get_company_terms_and_conditions.json`);
        this.termandconditions=response.data.terms_and_conditions;
      }
      catch (err) {
          console.log(err, "error");
      }
  };


    @action get_companies = async (options={}) => {
      this.companies=[];
        try {

          let response=  await get(`/companies/get_companies.json`);
          this.companies=response.data.companies;
        }
        catch (err) {
            console.log(err, "error");
        }
    };
    @action get_company = async (options={}) => {
      this.company={};
        try {

          let response=  await get(`/companies/get_company.json`,options);
          this.company=response.data.company;
        }
        catch (err) {
            console.log(err, "error");
        }
    };

}

export default CompanyStore;
export const CompanyStoreContext = createContext(new CompanyStore())
