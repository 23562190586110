import axios  from "../axios/index";

function get(url,options={}) {
    return axios.get(`${url}`,options);
}
function post(url, data, options={}) {
    return axios.post(`${url}`, data,options);
}
function deleted(url,options={}) {
    return axios.delete(`${url}`, options);
}
function put(url, data, options={}) {
    return axios.put(`${url}`, data, options);
}

export  { get, post,deleted, put };