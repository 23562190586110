import AuthStore from "./auth.store";
import ReportStore from "./reports.store";
import EventStore from "./events.store";
import WishlistStore from "./wishlist.store";
import UserStore from "./user.store";
import BidStore from "./bids.store";
import StripeStore from "./stripe.store";
import RequestStore from "./requests.store";
import CompanyStore from "./company.store";
import MarketPlace from "./marketplace.store";
import RaffleStore from "./raffle.store";
import AthletesStore from "./athletes.store";



class RootStore {
  constructor() {
    this.AuthStore = new AuthStore();
    this.ReportStore = new ReportStore()
    this.EventStore = new EventStore()
    this.WishlistStore=new WishlistStore()
    this.UserStore=new UserStore()
    this.BidStore=new BidStore()
    this.StripeStore=new StripeStore()
    this.RequestStore=new RequestStore()
    this.CompanyStore=new CompanyStore()
    this.MarketPlace=new MarketPlace()
    this.RaffleStore=new RaffleStore()
    this.AthletesStore=new AthletesStore()
  
  }
}
export default RootStore;
