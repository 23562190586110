import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

const PrivateRoute = inject("AuthStore")(
  observer(({ AuthStore, component: Component, props, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          AuthStore.isAuthenticated === true ? (
            <Component {...props} />
            // <Redirect to = "/sources" />
          ) : (
            <Redirect to="/" />
         
            )
        }
      />
    );
  })
);

export default PrivateRoute;
