import axios from "../axios/index";

const setAuthToken = (token,user_id=null) => {
  
    axios.interceptors.request.use(function (config) {
      if(token) {
        config.headers = {...config.headers, ...{"Authorization": token}}
        if(user_id){
          config.params = {...config.params, ...{"user_id": user_id}}  
        }       
      }
      else {
        delete config.headers["Authorization"]
        
      }
      return config
  }, function (err) {
    return Promise.reject(err)
  }
  )
  
};

export default setAuthToken;
