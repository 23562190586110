export const changeantdColor = async () => {
    setTimeout(async () => {
      let companyThemeColor = JSON.parse(sessionStorage.getItem('company_details'))?.company?.theme_color;
      while (!companyThemeColor) {
        await new Promise(resolve => setTimeout(resolve, 500));
        companyThemeColor = JSON.parse(sessionStorage.getItem('company_details'))?.company?.theme_color;
      }
      if (companyThemeColor === 'white') {
      const popupElements = await document.getElementsByClassName('ant-popover-inner-content');
      const dropdownElements = await document.getElementsByClassName('ant-dropdown-menu');
      const redeemsteps = await document.getElementsByClassName('ant-steps');
      const redeemstepsicon = await document.querySelectorAll('.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon');
      const redeemstepsicon2 = await document.querySelectorAll('.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon');
      const redeemstepsicon3 = await document.querySelectorAll('.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon .anticon');
      const redeembuttons = await document.getElementsByClassName('ant-btn ');
      const modalcontent = await document.querySelectorAll('.modal-content, .modal-body, .ant-select-dropdown');
      const modalcontentdata = await document.querySelectorAll('.modal-body h1, .modal-body h2, .modal-body h3, .modal-body h4, .modal-body h5, .modal-body h6, .ant-select-dropdown-menu-item, .ant-select-selection-selected-value');
      const modalcontentdropdown = await document.querySelectorAll('.ant-select-dropdown-menu-item');
      const modalcontentdropdowndisabled = await document.querySelectorAll('.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled)');
      const antdmodalcontent = await document.getElementsByClassName('ant-modal-body');
      const antdmodalTitle = await document.getElementsByClassName('ant-modal-title');
      const antdmodalheader = await document.getElementsByClassName('ant-modal-header');
      const antdmodalfooter = await document.getElementsByClassName('ant-modal-footer');

      if (popupElements) {
        Array.from(popupElements).forEach(async (element) => {
          element.style.backgroundColor = 'white';
          const heading = await element.getElementsByTagName('h4');
          const message = await document.getElementsByClassName('ant-popover-message-title');

          if (heading) {
            Array.from(heading).forEach((headingcolor) => {
              headingcolor.style.setProperty('color', 'black', 'important');
            });
          }
          if (message) {
            Array.from(message).forEach((messagecolor) => {
              messagecolor.style.setProperty('color', 'black', 'important');
            });
          }
        });
      }
      if (modalcontent) {
        Array.from(modalcontent).forEach(async (element) => {
          element.style.setProperty('background-color', '#CACACA', 'important');
        });
      }
      if (modalcontentdata) {
        Array.from(modalcontentdata).forEach(async (element) => {
          element.style.color = 'black';
        });
      }
      if (antdmodalcontent) {
        Array.from(antdmodalcontent).forEach(async (element) => {
          element.style.backgroundColor = '#CACACA';
        });
      }
      if (modalcontentdropdowndisabled) {
        Array.from(modalcontentdropdowndisabled).forEach(async (element) => {
          element.style.backgroundColor = '#f2f2f2';
        });
      }
      if (antdmodalTitle) {
        Array.from(antdmodalTitle).forEach(async (element) => {
          element.style.color = 'black';
        });
      }
      if (antdmodalheader) {
        Array.from(antdmodalheader).forEach(async (element) => {
          element.style.backgroundColor = '#F5F5F5';
        });
      }
      if (antdmodalfooter) {
        Array.from(antdmodalfooter).forEach(async (element) => {
          element.style.backgroundColor = '#F5F5F5';
        });
      }
      if (dropdownElements) {
        Array.from(dropdownElements).forEach(async (element) => {
          element.style.setProperty('background-color', 'white', 'important');
          const textdivcolor = element.querySelectorAll('div');
          const label = await element.getElementsByTagName('label');
          const heading2 = await element.getElementsByTagName('h2');
          const heading3 = await element.getElementsByTagName('h3');
          const heading4 = await element.getElementsByTagName('h4');
          const itemhover = await element.querySelectorAll('.ant-col.filter-column');
        const textsuccess = await element.getElementsByClassName('text-success');
          if (label) {
            Array.from(label).forEach((labelcolor) => {
              labelcolor.style.setProperty('color', 'black', 'important');
            });
          }
          if (heading2) {
            Array.from(heading2).forEach((heading2color) => {
              heading2color.style.setProperty('color', 'black', 'important');
            });
          }
          if (textsuccess) {
            Array.from(textsuccess).forEach((textsuccesscolor) => {
              textsuccesscolor.style.setProperty('color', '#04DEB4', 'important');
            });
          }
          if (heading3) {
            Array.from(heading3).forEach((heading3color) => {
              heading3color.style.setProperty('color', 'black', 'important');
            });
          }
          if (heading4) {
            Array.from(heading4).forEach((heading4color) => {
              heading4color.style.setProperty('color', 'black', 'important');
            });
          }
          if (textdivcolor) {
            Array.from(textdivcolor).forEach((textdivcolor) => {
              textdivcolor.style.setProperty('color', 'black', 'important');
            });
          }
          if (itemhover) {
            Array.from(itemhover).forEach((item) => {
              item.addEventListener('mouseover', () => {
                item.style.setProperty('background-color', '#f2f2f2', 'important');
              });

              item.addEventListener('mouseout', () => {
                item.style.setProperty('background-color', 'white', 'important');
              });
            });
          }
        });
      }


      if (redeemsteps) {
        Array.from(redeemsteps).forEach(async (element) => {
          element.style.backgroundColor = '#CACACA';
          const antdheadings = await element.getElementsByClassName('ant-steps-item-title');
          if (antdheadings) {
            Array.from(antdheadings).forEach((item) => {
                item.style.setProperty('color', 'black', 'important');
            });
          }
        });
      }
      if (redeemstepsicon) {
        Array.from(redeemstepsicon).forEach(async (element) => {
          element.style.setProperty('color', '#283652', 'important');
        });
      }
      if (redeemstepsicon2) {
        Array.from(redeemstepsicon2).forEach(async (element) => {
          element.style.setProperty('color', 'white', 'important');
        });
      }
      if (redeemstepsicon3) {
        Array.from(redeemstepsicon3).forEach(async (element) => {
          element.style.setProperty('color', '#283652', 'important');
        });
      }


      if (redeembuttons) {
        Array.from(redeembuttons).forEach(async (element) => {
          element.style.setProperty('background-color', '#CACACA', 'important');
          element.style.setProperty('color', 'black', 'important');
        });
      }
      if (modalcontentdropdown) {
        Array.from(modalcontentdropdown).forEach((item) => {
          item.addEventListener('mouseover', () => {
            item.style.setProperty('background-color', '#f2f2f2', 'important');
          });

          item.addEventListener('mouseout', () => {
            item.style.setProperty('background-color', '#CACACA', 'important');
          });
        });
      }
  }
  
  else {
    const dropdownElements = await document.getElementsByClassName('ant-dropdown-menu');
    const redeemsteps = await document.getElementsByClassName('ant-steps');
    const antdmodalcontent = await document.getElementsByClassName('ant-modal-body');
    const redeemstepsicon = await document.querySelectorAll('.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon');
    const redeemstepsicon2 = await document.querySelectorAll('.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon');
    const redeemstepsicon3 = await document.querySelectorAll('.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon .anticon');
    const modalcontent = await document.querySelectorAll('.modal-content, .modal-body, .ant-select-dropdown');
    const modalcontentdata = await document.querySelectorAll('.modal-body h1, .modal-body h2, .modal-body h3, .modal-body h4, .modal-body h5, .modal-body h6, .ant-select-dropdown-menu-item, .ant-select-selection-selected-value');
    const modalcontentdropdown = await document.querySelectorAll('.ant-select-dropdown-menu-item');
    const modalcontentdropdowndisabled = await document.querySelectorAll('.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled)');
    if (dropdownElements) {
      Array.from(dropdownElements).forEach(async (element) => {
        element.style.setProperty('background-color', '#2a2a2a', 'important');
        const textdivcolor = element.querySelectorAll('div');
        const label = await element.getElementsByTagName('label');
        const heading2 = await element.getElementsByTagName('h2');
        const heading3 = await element.getElementsByTagName('h3');
        const heading4 = await element.getElementsByTagName('h4');
        const itemhover = await element.querySelectorAll('.ant-col.filter-column');
        const textsuccess = await element.getElementsByClassName('text-success');
        if (label) {
          Array.from(label).forEach((labelcolor) => {
            labelcolor.style.setProperty('color', 'white', 'important');
          });
        }
        if (heading2) {
          Array.from(heading2).forEach((heading2color) => {
            heading2color.style.setProperty('color', 'white', 'important');
          });
        }
        if (textsuccess) {
          Array.from(textsuccess).forEach((textsuccesscolor) => {
            textsuccesscolor.style.setProperty('color', '#04DEB4', 'important');
          });
        }
        if (heading3) {
          Array.from(heading3).forEach((heading3color) => {
            heading3color.style.setProperty('color', 'white', 'important');
          });
        }
        if (heading4) {
          Array.from(heading4).forEach((heading4color) => {
            heading4color.style.setProperty('color', 'white', 'important');
          });
        }
        if (textdivcolor) {
          Array.from(textdivcolor).forEach((textdivcolor) => {
            textdivcolor.style.setProperty('color', 'white', 'important');
          });
        }
        if (itemhover) {
          Array.from(itemhover).forEach((item) => {
            item.addEventListener('mouseover', () => {
              item.style.setProperty('background-color', 'black', 'important');
            });

            item.addEventListener('mouseout', () => {
              item.style.setProperty('background-color', '#2a2a2a', 'important');
            });
          });
        }
      });
    }

    if (antdmodalcontent) {
      Array.from(antdmodalcontent).forEach(async (element) => {
        element.style.color = 'white';
      });
    }
    if (redeemsteps) {
      Array.from(redeemsteps).forEach(async (element) => {
        const antdheadings = await element.getElementsByClassName('ant-steps-item-title');
        if (antdheadings) {
          Array.from(antdheadings).forEach((item) => {
              item.style.setProperty('color', 'white', 'important');
          });
        }
      });
    }
    if (redeemstepsicon) {
      Array.from(redeemstepsicon).forEach(async (element) => {
        element.style.setProperty('color', '#283652', 'important');
      });
    }
    if (redeemstepsicon2) {
      Array.from(redeemstepsicon2).forEach(async (element) => {
        element.style.setProperty('color', 'white', 'important');
      });
    }
    if (redeemstepsicon3) {
      Array.from(redeemstepsicon3).forEach(async (element) => {
        element.style.setProperty('color', '#283652', 'important');
      });
    }
    if (modalcontent) {
      Array.from(modalcontent).forEach(async (element) => {
        element.style.setProperty('background-color', '#2a2a2a', 'important');
      });
    }
    if (modalcontentdata) {
      Array.from(modalcontentdata).forEach(async (element) => {
        element.style.color = 'white';
      });
    }
    if (modalcontentdropdown) {
      Array.from(modalcontentdropdown).forEach((item) => {
        item.addEventListener('mouseover', () => {
          item.style.setProperty('background-color', 'black', 'important');
        });

        item.addEventListener('mouseout', () => {
          item.style.setProperty('background-color', 'rgb(42, 42, 42)', 'important');
        });
      });
    }
    if (modalcontentdropdowndisabled) {
      Array.from(modalcontentdropdowndisabled).forEach(async (element) => {
        element.style.backgroundColor = 'black';
      });
    }

  }

    }, 200);
};
