

const Addcardicon=(icon)=>{



    switch(icon){
        case "Visa":case "visa":
            return "fa fab fa-cc-visa"


        case 'UnionPay': case "unionpay":
            return "fa fab fa-cc-stripe"
        
     
        case 'MasterCard': case "mastercard":
            return " fa fab fa-cc-mastercard"

       case 'Discover':
            return "fa fab fa-cc-discover"

        case "American Express":case "amex":
          return "fa fab fa-cc-amex"
        
        case "JCB":case "jcb":
            return "fa fab fa-cc-jcb"
          

            default:
                return "fa fab fa-credit-card"
          
    }



}
export default Addcardicon;